import React, {MouseEventHandler, useEffect, useState} from 'react';
import {RowItemContainer, RowItemsRightSide} from '../../../../microfrontend/styled/login.styled';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../../microfrontend/login/SavedAccountOrganizationRowItems';
import {checkIfUserIsSignedIn} from '../../../../utils/userHelper/userUtils';
import {localStorageService} from '../../../../services/localStorageService';
import {OrganizationAccountsCacheData, OrgLoginMethods} from '../../../../types/sta';
import {AUTH_INFO, INTRO_DONE, ORGANIZATION_ACCOUNTS_DATA} from '../../../../constants/storageKeys';
import {AuthPayloadDTO, STALogin} from '../../../../types';
import {
  LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT,
  LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT,
  LOGIN_PAGE_REMOVE_ALL_ACCOUNT,
} from '../../../../constants/strings';
import {toast} from 'react-toastify';
import {LogoutViewModel} from '../view-models/LogoutViewModel';
import {AccountSelectionViewModel} from '../view-models/AccountSelectionViewModel';
import {LogoutAllAccountsModal} from '../components/LogoutAllAccountsModal';
import {AccountLogoutModal} from '../components/AccountLogoutModal';
import {AddButtonV2} from '../../../../microfrontend/svgs/AddButtonV2';
import {HCLabelOne} from '../../../../components/HypercareComponents';
import {CloseButtonV2} from '../../../../microfrontend/svgs/CloseButtonV2';
import {GearIcon} from '../../../../svgs/GearIcon';
import {actions} from '../../../../redux/actions/currentAccount';
import store from '../../../../redux';
import {AuthContext} from '../../../../auth';
import getParsedAuthInfo from '../../../../utils/localStorageHandler';
import {AccountSelectionEditModeTitle} from '../../../../microfrontend/login/SavedAccountsTitle';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {muiTheme} from 'src/styles/theme';
import {getCurrentLoggedInAccount, logoutAllSavedAccounts, sortSavedAccounts} from '../../../../utils/sta/staUtils';
import {LogoutOrContinueModal} from '../components/LogoutOrContinueModal';
import {useUnreadChatCount} from '../../../../hooks/useUnreadChatCount';
import {UnreadChatCountBubble} from '../../styled/login.styled';

interface IDropdownProps {
  auth0props: Auth0ContextInterface;
  STALogin: STALogin;
  logout: () => void;
  currentActiveAccount: AuthPayloadDTO;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Dropdown = ({STALogin, auth0props, logout, currentActiveAccount, setOpen}: IDropdownProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const [currentSelectedAcc, setCurrentSelectedAcc] = useState<AuthPayloadDTO | null>(null);
  const [showLogoutAccountModal, setShowLogoutAccountModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
  const [showLogoutOrContinueModal, setShowLogoutOrContinueModal] = useState(false);

  const {logoutOfAccount, removeAccount} = LogoutViewModel(logout);
  const [showLogoutAllAccountsModal, setShowLogoutAllAccountsModal] = useState(false);
  const {handleLoginToClickedAccount} = AccountSelectionViewModel();
  const authInfo = getParsedAuthInfo();
  const {unreadCount, fetchUnreadCount} = useUnreadChatCount();

  useEffect(() => {
    const getCount = async () => {
      await fetchUnreadCount();
    };

    getCount();
  }, []);

  const handleOpenRemoveAccountModal = (organization: AuthPayloadDTO) => {
    let isUserSignedIn = checkIfUserIsSignedIn(organization);
    setCurrentSelectedAcc(organization);

    isUserSignedIn ? setShowLogoutAccountModal(true) : setShowRemoveAccountModal(true);
  };

  const handleRemoveAccount = () => {
    const removeAccountResponse = removeAccount(currentSelectedAcc);

    if (removeAccountResponse?.success) {
      toast.info(
        `${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name}) has been removed from browser.`,
      );
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowRemoveAccountModal(false);
    setShowLogoutAccountModal(false);
  };

  const handleLogoutOfAccount = () => {
    const logoutResponse = logoutOfAccount(currentSelectedAcc);

    if (logoutResponse?.success) {
      toast.info(
        `Logged out of account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name})`,
      );
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
  };

  const handleRemoveAllAccountsOnDevice = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(INTRO_DONE);
    localStorageService.removeItem(AUTH_INFO);
    window.location.assign(`/`);
  };

  const handleLoginToAccount = async (savedOrg: AuthPayloadDTO) => {
    if (authInfo?.user.id === savedOrg.user.id) {
      return;
    }

    const isSignedIn = checkIfUserIsSignedIn(savedOrg);
    store.dispatch(actions.setCurrentAccount(savedOrg));

    if (isSignedIn) {
      await STALogin(savedOrg.organization, savedOrg, savedOrg.user.email || '');
    } else {
      const res = await handleLoginToClickedAccount(savedOrg);

      if (res?.error) {
        toast.error(res.error);
      }
      if (res?.auth0Id) {
        auth0props.loginWithRedirect({
          connection: res.auth0Id,
          scope: 'openid profile email',
        });
      }
      switch (res?.screen) {
        case OrgLoginMethods.OTP:
          window.routerHistory.push(`/accountSwitch/otp?challengeId=${res.challengeId}`);
          return;
        case OrgLoginMethods.PASSWORD:
          window.routerHistory.push(`/accountSwitch/password?email=${savedOrg.user.email?.replace('+', '%2b')}`);
          return;
        default:
          break;
      }
    }
    setOpen(false);
    window.location.reload();
  };

  const handleContinue = () => {
    window.routerHistory.push('/accountSwitch/accountDiscovery');
  };

  const handleLogout = () => {
    if (currentCacheData) {
      logoutAllSavedAccounts(currentCacheData);
      toast.success('Successfully logged out of each account!');
    }
    logout();
    setShowLogoutOrContinueModal(false);
  };

  const handleLogoutAndRemove = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(AUTH_INFO);
    window.location.assign(`/`);
    setShowLogoutOrContinueModal(false);
    toast.info(`Successfully logged out from all accounts`);
  };

  const sortedAccounts = sortSavedAccounts(currentCacheData?.savedOrganizations || []);

  const getCountFromUnreadList = (userId: string): string | null => {
    if (!unreadCount) return null;
    const target = unreadCount[userId];
    return target ? (target > 99 ? '99+' : `${target}`) : null;
  };

  return (
    <div>
      {isEditMode && (
        <AccountSelectionEditModeTitle
          onClick={(e) => {
            e.stopPropagation();
            setIsEditMode(false);
          }}
          title={'Manage accounts'}
          padding={'24px 16px'}
        />
      )}{' '}
      <RowItemContainer>
        {sortedAccounts?.map((savedOrg) => (
          <SavedAccountOrganizationRowItems
            key={savedOrg.user.id}
            user={savedOrg.user}
            organization={savedOrg.organization}
            isSignedIn={checkIfUserIsSignedIn(savedOrg)}
            isEditMode={isEditMode}
            handleRowClick={(e) => {
              e.stopPropagation();
              handleLoginToAccount(savedOrg);
            }}
            isCurrentActiveAccount={() => {
              const loggedInAccount = getCurrentLoggedInAccount();
              return loggedInAccount?.user.id === savedOrg.user.id ?? false;
            }}
            rightSideContent={
              isEditMode ? (
                <RowItemsRightSide
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenRemoveAccountModal(savedOrg);
                  }}
                >
                  <CloseButtonV2 />
                </RowItemsRightSide>
              ) : unreadCount && unreadCount[savedOrg.user.id] ? (
                <RowItemsRightSide>
                  <UnreadChatCountBubble>{getCountFromUnreadList(savedOrg.user.id)}</UnreadChatCountBubble>
                </RowItemsRightSide>
              ) : (
                <></>
              )
            }
          />
        ))}
        <SavedAccountActionButtons
          onIconClick={(e) => {
            e.stopPropagation();
            setShowLogoutOrContinueModal(true);
          }}
          icon={<AddButtonV2 />}
          description={
            <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
              {LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT}
            </HCLabelOne>
          }
        />
        {isEditMode ? (
          <SavedAccountActionButtons
            icon={<CloseButtonV2 />}
            onIconClick={(e) => {
              e.stopPropagation();
              setShowLogoutAllAccountsModal(true);
            }}
            description={
              <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                {LOGIN_PAGE_REMOVE_ALL_ACCOUNT}
              </HCLabelOne>
            }
          />
        ) : (
          <SavedAccountActionButtons
            icon={<GearIcon />}
            onIconClick={(e) => {
              e.stopPropagation();
              setIsEditMode(true);
            }}
            description={
              <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
              </HCLabelOne>
            }
          />
        )}
      </RowItemContainer>
      {showLogoutAllAccountsModal && (
        <LogoutAllAccountsModal
          isOpen={showLogoutAllAccountsModal}
          setIsOpen={setShowLogoutAllAccountsModal}
          handleOnClick={handleRemoveAllAccountsOnDevice}
        />
      )}
      {showLogoutAccountModal && (
        <AccountLogoutModal
          title={`Logout out of ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname || ''} (${
            currentSelectedAcc?.organization.name
          })`}
          subTitle={`We recommend also removing your account from device upon logging out if you’re using a shared device.`}
          isOpen={showLogoutAccountModal}
          setIsOpen={setShowLogoutAccountModal}
          modalButtons={[
            {
              type: 'primary',
              buttonLabel: 'Logout & Remove',
              onClickHandler: handleRemoveAccount,
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Logout',
              onClickHandler: handleLogoutOfAccount,
              id: 'remove-btn',
            },
          ]}
        />
      )}
      {showRemoveAccountModal && (
        <AccountLogoutModal
          title={`Remove account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name}) from browser?`}
          subTitle={`You’ll need to enter your credentials again the next time you log into Hypercare on this device.`}
          isOpen={showRemoveAccountModal}
          setIsOpen={setShowRemoveAccountModal}
          modalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowRemoveAccountModal(false),
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Remove',
              onClickHandler: handleRemoveAccount,
              id: 'remove-btn',
            },
          ]}
        />
      )}
      {showLogoutOrContinueModal && (
        <LogoutOrContinueModal
          open={showLogoutOrContinueModal}
          onRequestClose={() => setShowLogoutOrContinueModal(false)}
          onHandleContinue={() => handleContinue()}
          onHandleLogoutAndRemove={() => handleLogoutAndRemove()}
          onHandleLogout={() => handleLogout()}
        />
      )}
    </div>
  );
};

export const SwitchAccountsDropdownItems = ({
  currentActiveAccount,
  setOpen,
}: {
  currentActiveAccount: AuthPayloadDTO;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <AuthContext.Consumer>
      {({auth0props, STALogin, logout}) => (
        <Dropdown
          currentActiveAccount={currentActiveAccount}
          logout={logout}
          STALogin={STALogin}
          auth0props={auth0props}
          setOpen={setOpen}
        />
      )}
    </AuthContext.Consumer>
  );
};
