import React, {useState} from 'react';
import {CircularProgress} from '@mui/material';
import NewLoginFormHeader from '../../ components/NewLoginFormHeader';
import {StyledTextField} from '../../../../styles/styled-components/StyledInputField';
import {HelperBox, InputsWrapper, LoginContainer} from '../../../../microfrontend/styled/login.styled';
import {StyledSubmitAddressButton} from '../../styled/login.styled';
import {Formik} from 'formik';
import {HCLabelTwo} from '../../../../components/HypercareComponents';
import {AccountDiscoveryViewModel} from '../view-models/AccountDiscoveryViewModel';
import {CurrentLoginStep, OrgLoginMethods} from '../../../../types/sta';
import {LoginOTPView} from '../../../../microfrontend/login/LoginOTPView';
import {emailValidationSchema} from '../../../../utils/validations';
import * as yup from 'yup';
import {ContactUsFooter} from '../components/ContactUsFooter';
import {toast} from 'react-toastify';
import {muiTheme} from 'src/styles/theme';
import {MAX_TEXTFIELD_LIMIT} from 'src/constants/login';

interface IAccountDiscoveryCoordinatorProps {
  showFindMyOrgView: () => void;
}

interface IAccountDiscoveryFlowEmailViewProps {
  handleOnEmailSubmit: (email: string) => void;
  showFindMyOrgView: () => void;
  isLoading: boolean;
  error: boolean | string;
}

const validationSchema = yup.object().shape<{email: string}>({
  email: emailValidationSchema,
});

export const AccountDiscoveryFlowEmailView = ({
  handleOnEmailSubmit,
  showFindMyOrgView,
  isLoading,
  error,
}: IAccountDiscoveryFlowEmailViewProps) => {
  return (
    <LoginContainer width={'600px'} padding={'32px'}>
      <NewLoginFormHeader isAccountLocked={false} />
      <Formik
        initialValues={{email: ''}}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values, actions) => {
          await handleOnEmailSubmit(values.email.trim());

          actions.setSubmitting(false);
        }}
      >
        {({handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting}) => (
          <form onSubmit={handleSubmit}>
            <InputsWrapper>
              <StyledTextField
                data-testid="emailInput"
                fullWidth
                type={'email'}
                variant="outlined"
                placeholder={'you@hypercare.com'}
                label={'Email'}
                inputProps={{maxLength: MAX_TEXTFIELD_LIMIT}}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                value={values.email}
                helperText={error || (touched.email && errors.email)}
                error={!!error || (touched.email && Boolean(errors.email))}
              />
            </InputsWrapper>

            <HelperBox paddingBottom={'12px'} onClick={showFindMyOrgView} style={{cursor: 'pointer'}}>
              <HCLabelTwo color={muiTheme.colors.chatTeal}>Manually login to an org</HCLabelTwo>
            </HelperBox>

            <StyledSubmitAddressButton data-testid="addressSubmitButton" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> Submitting
                </>
              ) : (
                'Next'
              )}
            </StyledSubmitAddressButton>
            <ContactUsFooter />
          </form>
        )}
      </Formik>
    </LoginContainer>
  );
};

export const AccountDiscoveryCoordinator = ({showFindMyOrgView}: IAccountDiscoveryCoordinatorProps) => {
  const {enterEmail, submitOTP, handleResendOTP} = AccountDiscoveryViewModel();
  const [currentStep, setCurrenStep] = useState(CurrentLoginStep.STEP_1);
  const [email, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [challengeId, setChallengeId] = useState('');
  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const [OTPError, setOTPError] = useState('');

  const handleSubmitOTP = async (otp: string) => {
    setIsOTPLoading(true);
    const res = await submitOTP({challengeId, otp, email});
    if (res?.error) {
      setOTPError(res.error);
    }
    setIsOTPLoading(false);
  };

  const handleSubmitEmail = async (email: string) => {
    setIsEmailLoading(true);
    setCurrentEmail(email);
    const res = await enterEmail(email.toLowerCase());

    if (res?.error) {
      setEmailError(res.error);
    }
    if (res?.auth0Id) {
      //loginWithAuth0(res.auth0Id);
    }

    if (res?.screen === OrgLoginMethods.OTP) {
      setCurrenStep(CurrentLoginStep.STEP_2);
      res.challengeId && setChallengeId(res.challengeId);
      return;
    }
    setIsEmailLoading(false);
  };

  const handleGoToPreviousStep = () => {
    setIsEmailLoading(false);
    setEmailError('');
    setCurrenStep(CurrentLoginStep.STEP_1);
  };

  const resendOTPCode = async (email: string) => {
    const res = await handleResendOTP(email);

    if (res?.challengeId) {
      setChallengeId(res.challengeId);
      toast.success(`Successfully resent verification code`);
    }

    if (res?.error) {
      toast.error('Unable to resend verification code');
    }
  };

  const renderSteps = (currentStep: CurrentLoginStep) => {
    switch (currentStep) {
      case CurrentLoginStep.STEP_1:
        return (
          <AccountDiscoveryFlowEmailView
            handleOnEmailSubmit={handleSubmitEmail}
            showFindMyOrgView={showFindMyOrgView}
            isLoading={isEmailLoading}
            error={emailError}
          />
        );
      case CurrentLoginStep.STEP_2:
        return (
          <LoginOTPView
            handleGoBack={handleGoToPreviousStep}
            handleNext={handleSubmitOTP}
            resend={resendOTPCode}
            isResending={isResendingOTP}
            error={OTPError}
            currentSelectedEmail={email}
            isLoading={isOTPLoading}
          />
        );
    }
  };

  return <>{renderSteps(currentStep)}</>;
};
