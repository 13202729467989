import React from 'react';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {Container, ContactSupportLink} from '../styled/signup.styled';
import {HCLabelTwo} from 'src/components/HypercareComponents';
import {muiTheme} from 'src/styles/theme';

interface ISignupFooterProps {
  recoverAccount?: boolean;
}

const SignupFooter = (props: ISignupFooterProps) => {
  const onContactSupport = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpContactSupport,
    });
  };

  return (
    <Container>
      <HCLabelTwo color={muiTheme.colors.greyishBrown}>
        Need help?
        <ContactSupportLink
          href="https://www.hypercare.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onContactSupport}
        >
          Contact us
        </ContactSupportLink>
      </HCLabelTwo>
    </Container>
  );
};

export default SignupFooter;
