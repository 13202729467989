import React from 'react';
import ApiHelper from 'src/api/index';
import {ResetPasswordRepository} from '../../data/repository/ResetPasswordRepository';
import {TOO_MANY_CHALLENGES} from '../../constants/networkError';

export const ResetPasswordViewModel = () => {
  const {fetchMaskedAddressesForUser, requestResetPasswordMutation} = ResetPasswordRepository();
  const getMaskedAddresses = async ({email, organizationUrl}) => {
    const result = await fetchMaskedAddressesForUser({email, organizationUrl});

    console.log(result, 'result');
    if (result && 'errors' in result) {
      return {error: 'Error'};
    }

    switch (result?.data?.user.__typename) {
      case 'OrganizationNotFound':
      case 'UserNotFound':
        return {error: 'Error'};
      case 'PublicUser':
        return {data: result.data.user.maskedAddresses};
    }

    return {error: 'Error'};
  };

  const initiateResetPasswordFlow = async (addressId: string) => {
    const result = await ApiHelper.PrivateEndpoints.initiatePasswordResetFlowRequest({addressId});

    if (!result || result.data.errors?.[0]) {
      if (result?.data?.errors?.[0].name === TOO_MANY_CHALLENGES) {
        return {error: TOO_MANY_CHALLENGES};
      } else {
        return {error: 'Error occurred when initiating reset password flow request'};
      }
    }

    return {success: true};
  };

  const validateOTPToken = async (challengeId: string, token: string) => {
    const response = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, token);

    console.log(response, 'response');
    if (!response || response.data?.errors?.[0]) {
      switch (response.data.errors?.[0].name) {
        case 'ChallengeTimeExpired':
          return {error: 'ChallengeTimeExpired'};
        case 'NoChallengeEntry':
          return {error: 'NoChallengeEntry'};
        default:
          return {error: 'Error occurred when initiating reset password flow request'};
      }
    }

    if (response.data?.response.challengeId) {
      return {success: true};
    }
  };

  const resetPasswordRequest = async (challengeId: string, password: string) => {
    const res = await requestResetPasswordMutation({challengeId, password});
    console.log(res, 'res');

    switch (res?.data?.resetPassword.__typename) {
      case 'VulnerablePassword':
        return {error: 'Your password is too weak. Pick a stronger one to continue'};
      case 'PasswordRequirementsNotMet':
        return {error: 'The password requirements have not been met, please try again'};
      case 'ChallengeFailed':
      case 'ChallengeNotCompleted':
      case 'UserNotFound':
      case 'ChallengeNotFound':
        return {error: 'Error occurred with reset password flow request'};

      case 'PublicUser':
        return {success: true};

      default:
        return {error: 'Unknown error occurred'};
    }
  };

  return {getMaskedAddresses, initiateResetPasswordFlow, validateOTPToken, resetPasswordRequest};
};
