import React, {useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {ITellUsAboutYourSelfStepData} from 'src/types';
import {roleSchema} from 'src/utils/validations';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewNextStepButton from '../components/NextStepButton';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewStepFooter from '../components/step-footer-copy';
import SignupPage from 'src/microfrontend/signup/SignupPage';
import {MAX_TEXTFIELD_LIMIT} from 'src/constants/login';
import {Typography} from '@mui/material';

const validationSchema = yup.object().shape<ITellUsAboutYourSelfStepData>({
  jobTitle: roleSchema,
});

export const SignupJobTitleView = ({viewModel, inputData, onBackButtonClick, orgName}) => {
  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupProfile);
  }, []);
  const [error, setError] = useState('');
  const onNextClick = (values: {jobTitle: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        job_title: values.jobTitle,
      },
    });
  };

  return (
    <SignupPage
      currentStep={6}
      isDisabled={false}
      title="Enter your job title"
      description={`What’s your role at ${orgName}?`}
      onBackButtonClick={onBackButtonClick}
      content={
        <Formik
          initialValues={{
            jobTitle: inputData.jobTitle,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setError('');

            const payload = {
              email: inputData.email,
              challengeId: inputData.challengeId,
              inviteCode: inputData.orgInviteCode,
              orgName: inputData.orgName,
              organizationUrl: inputData.organizationUrl,
              activateShellAccountFlow: inputData.activateShellAccountFlow,
              // phoneNumber: inputData.phoneNumber, Not handled by backend yet
              userDetails: {
                firstName: inputData.firstName.trim(),
                lastName: inputData.lastName.trim(),
                role: values.jobTitle.trim(),
              },
            };

            const result = await viewModel.handleNextButtonPressed(payload);
            if (result.error) {
              setError(result.error);
            }
          }}
        >
          {({
            handleSubmit,
            values,
            isValid,
            handleChange,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <StyledTextField
                margin="normal"
                fullWidth={true}
                variant="outlined"
                value={values.jobTitle}
                inputProps={{maxLength: MAX_TEXTFIELD_LIMIT}}
                label="Job Title"
                name="jobTitle"
                disabled={isSubmitting}
                placeholder="Job Title (Suggested Format-> Physician/Cardiology)"
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.jobTitle && errors.jobTitle}
                error={touched.jobTitle && Boolean(errors.jobTitle)}
              />
              {Boolean(error) && (
                <Typography color="error" style={{marginTop: 16}}>
                  {error}
                </Typography>
              )}
              <NewNextStepButton
                label="Next"
                onClick={() => onNextClick(values)}
                loading={isSubmitting}
                loadingLabel="Creating account..."
                disabled={!isValid}
              >
                Next
              </NewNextStepButton>
            </form>
          )}
        </Formik>
      }
    />
  );
};
