import gql from 'graphql-tag';

export const REMOVE_ADDRESS_MUTATION = gql`
  mutation RemoveAddress($address: String!) {
    selfMutation {
      address(address: $address) {
        __typename
        ... on AddressMutation {
          remove {
            ... on OrganizationMember {
              id
            }
          }
        }
        ... on AddressNotFound {
          message
        }
      }
    }
  }
`;
