import React, {useState} from 'react';
import styled from '@emotion/styled';
import {InvitedUser, InviteStatus} from 'src/types';
import {toast} from 'react-toastify';
import {CircularProgress, IconButton} from '@mui/material';

import UserAvatar from './views/UserAvatar';
import CloseIcon from 'src/svgs/CloseIcon';
import UserItemButton from './views/UserItemButton';
import UserItemDetail from './views/UserItemDetail';
import useReInviteUser from './hooks/useReInviteUser';
import useCancelInvite from './hooks/useCancelInvite';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import useRefetchInvitedUsers from '../InvitedUserSection/hooks/useFetchInvitedUsers';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {usePaginatedPendingDataStateSTA} from './hooks/usePaginatedPendingDataStateSTA';
import {FetchPaginatedInvitesQueryResponse} from 'src/gql/v2/query/FetchPaginatedInvites';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import InfiniteScroll from 'react-infinite-scroller';
import {SmallLoader} from 'src/components/LoadingDiv';
import {FetchPaginatedSentInvitesQueryResponse} from 'src/gql/v2/query/FetchPaginatedSentInvites';

const PREFIX = 'InvitationListV2';

const classes = {
  inviteToast: `${PREFIX}-inviteToast`,
};

const UserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledUserListWrapper = styled(UserListWrapper)(({theme: Theme}) => ({
  [`& .${classes.inviteToast}`]: {
    background: '#4a4a4a',
    color: '#fff',
  },
}));
const Ellipsis = styled.div`
  font-size: 30px;
  :after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026';
    width: 0;
  }
  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
`;

const UserItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  invitedUserList: InvitedUser[];
  fetchMorePaginatedInvitesData: any;
  paginatedPendingInvitesData?: FetchPaginatedSentInvitesQueryResponse;
}

const InvitationListSTA = ({invitedUserList, fetchMorePaginatedInvitesData, paginatedPendingInvitesData}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const [closingInvite, setClosingInvite] = useState<InvitedUser | null>(null);
  const {tryCancelInvite, cancelInviteLoadingList} = useCancelInvite();
  const {succeedList, loadingList, tryResendInvitation} = useReInviteUser();
  const [showConfirmCancelInvite, setConfirmCancelInvite] = React.useState<boolean>(false);
  //   const [continuationId, setContinuationId] = useState(
  //     paginatedPendingInvitesData?.selfQuery?.invites.pageInfo.nextCursor,
  //   );
  const {
    isLoadingPendingAdditionalData,
    setIsLoadingPendingAdditionalData,
    isDonePendingRows,
    setIsDonePendingRows,
    additionalPendingRows,
    setAdditionalPendingRows,
    seenPendingNextCursors,
    setSeenPendingNextCursors,
  } = usePaginatedPendingDataStateSTA();

  const newInvitedUserListData = [...invitedUserList, ...(additionalPendingRows || [])];

  const newInvitedUserListDataNormalize = newInvitedUserListData.map((invitedUser) => {
    return {
      ...invitedUser,
      inviteStatus: invitedUser.status,
      address: {
        address: invitedUser.user?.firstName,
        __typename: 'Address',
      },
    };
  });

  const getMoreData = async () => {
    setIsLoadingPendingAdditionalData(true);
    const nextCursor = paginatedPendingInvitesData?.selfQuery?.invites.pageInfo.nextCursor;
    if (!nextCursor || seenPendingNextCursors.includes(nextCursor)) {
      setIsDonePendingRows(true);
      return;
    }

    try {
      await fetchMorePaginatedInvitesData({
        variables: {
          nextCursor,
          direction: 'next',
        },
        updateQuery: (
          previousResult: FetchPaginatedSentInvitesQueryResponse,
          {fetchMoreResult}: {fetchMoreResult: FetchPaginatedSentInvitesQueryResponse},
        ) => {
          const newFetchedUsers = fetchMoreResult?.selfQuery?.invites;

          if (newFetchedUsers.pageInfo.nextCursor === null) {
            setIsDonePendingRows(true);
          }

          setAdditionalPendingRows([...additionalPendingRows, ...newFetchedUsers.invites]);
          //   setContinuationId(newFetchedUsers.pageInfo.nextCursor);
        },
      });
    } finally {
      setSeenPendingNextCursors([...seenPendingNextCursors, nextCursor]);
    }
  };

  const handleResendInvitation = async (invitedUser: InvitedUser) => {
    try {
      const resultName = await tryResendInvitation(invitedUser, !!coSigningInvitesFF);

      toast.success(`Invite for "${resultName}" resent`, {
        autoClose: false,
        className: classes.inviteToast,
      });
    } catch (e) {
      console.error(e);
      toast.error('Failed to resend invitation, please check your internet connection and try again');
    }
  };

  const handleCancelInvite = async (invitedUser: InvitedUser) => {
    try {
      await tryCancelInvite(invitedUser);
      setConfirmCancelInvite(false);
      toast.success(
        `Invite for "${
          invitedUser?.user
            ? `${invitedUser?.user?.firstname} ${invitedUser?.user?.lastname}`
            : `${invitedUser?.address?.address}" has been withdrawn`
        }`,

        {
          className: classes.inviteToast,
        },
      );
    } catch (e) {
      console.error(e);
      toast.error('Failed to cancel invitation, please check your internet connection and try again');
    } finally {
      // handleOnComplete();
    }
  };
  return (
    <StyledUserListWrapper>
      <div style={{maxHeight: `calc(100vh - 605px)`, overflow: 'auto'}} id="scrollableDiv">
        <InfiniteScroll hasMore={!isDonePendingRows} loadMore={getMoreData} initialLoad={false} useWindow={false}>
          {newInvitedUserListDataNormalize.map((invitedUser) => (
            <UserItem key={invitedUser.id}>
              <UserAvatar invitedUser={invitedUser} />

              <UserItemDetail invitedUser={invitedUser} />

              {coSigningInvitesFF ? (
                invitedUser.inviteStatus === InviteStatus.pending_user_acceptance ? (
                  <UserItemButton
                    invitedUser={invitedUser}
                    succeedList={succeedList}
                    loadingList={loadingList}
                    handleResendInvitation={handleResendInvitation}
                  />
                ) : cancelInviteLoadingList.includes(invitedUser.id) ? (
                  <Ellipsis />
                ) : (
                  <IconButton
                    onClick={() => {
                      setClosingInvite(invitedUser);
                      setConfirmCancelInvite(true);
                    }}
                    size="large"
                  >
                    <CloseIcon width={10} height={10} color={'#000000'} />
                  </IconButton>
                )
              ) : (
                <UserItemButton
                  invitedUser={invitedUser}
                  succeedList={succeedList}
                  loadingList={loadingList}
                  handleResendInvitation={handleResendInvitation}
                />
              )}
            </UserItem>
          ))}
          {isLoadingPendingAdditionalData && !isDonePendingRows && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </InfiniteScroll>
      </div>

      {showConfirmCancelInvite && (
        <AlertModal
          title={`Withdraw request for "${
            closingInvite?.user
              ? `${closingInvite?.user?.firstname} ${closingInvite?.user?.lastname}`
              : `${closingInvite?.address?.address}"?`
          }`}
          subtitle={''}
          width={'sm'}
          titleFontSize={'21px'}
          closeAlertModal={() => setConfirmCancelInvite(false)}
          isAlertModalVisible={showConfirmCancelInvite}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setConfirmCancelInvite(false),
            },
            {
              type: 'primary',
              buttonLabel: 'Confirm Cancellation',
              onClickHandler: () => closingInvite && handleCancelInvite(closingInvite),
            },
          ]}
        />
      )}
    </StyledUserListWrapper>
  );
};

export default InvitationListSTA;
