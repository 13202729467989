import axios, {AxiosResponse} from 'axios';
import {v4 as uuid} from 'uuid';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import CheckAddressAvailable from 'src/gql/query/CheckAddressAvailable';
import CheckUserNameAvailable from 'src/gql/query/CheckUserNameAvailable';
import RequestVerificationPublicMutation from 'src/gql/mutation/RequestVerificationCodePublic';
import {VERIFY_ADDRESS_VALIDATION_CODE} from 'src/gql/mutation/VerifyAddressMutation';
import ResetPasswordMutation from 'src/gql/mutation/ResetPasswordMutation';
import RequestResetPasswordTokenMutation from 'src/gql/mutation/RequestResetPasswordMutation';
import VerifyResetPasswordMutation from 'src/gql/mutation/VerifyResetPasswordMutation';
import FetchOrganizationByEmailQuery from 'src/gql/query/FetchOrganizationByEmailQuery';
import FetchOrganizationByInviteCode from 'src/gql/query/FetchOrganizationByInviteCode';
import {print} from 'graphql/language/printer';
import {
  CreateUserPayload,
  VerifyAddressValidationPayload,
  RequestVerificationPayload,
  RequestResetPasswordCodePayload,
  ResetPasswordPayload,
  VerifyResetPasswordTokenPayload,
  CreateOrganizationPayload,
} from 'src/types';
import UnregisterPushToken from 'src/gql/mutation/UnregisterPushTokenMutation';
import GetSSODomains from 'src/gql/query/GetSSODomains';
import ListSSOProfilesQuery, {ListSSOProfilesQueryResponse} from 'src/gql/v2/query/ListSSOProfilesQuery';
import {CREATE_USER_MUTATION} from 'src/gql/v2/mutation/CreateUserMutation';
import {UserGeoData} from '../constants/user';
import {GEOGRAPHICAL_API_ERROR} from '../constants/networkError';
import {
  FETCH_ORGANIZATION_BY_URL_QUERY,
  FetchOrganizationByURLResponse,
} from '../gql/v2/query/FetchOrganizationByURLQuery';
import {FETCH_ORGANIZATION_BY_NAME, FetchOrganizationByNameResponse} from '../gql/v2/query/FetchOrganizationByName';

import {CREATE_ORG_MUTATION} from 'src/gql/v2/mutation/CreateOrgMutation';
import {FETCH_BASIC_USER_QUERY, FetchBasicUserResponse} from '../gql/v2/query/FetchBasicUser';
import {CREATE_STA_USER_MUTATION} from 'src/gql/v2/mutation/CreateSTAUserMutation';
import {
  FETCH_SSO_PROFILE_FOR_USER,
  FetchSSOProfileForUserResponse,
  MemberIdentifier,
} from '../gql/v2/query/FetchSSOProfileForUser';
import {ActivateShellAccountUserDetails} from '../types/sta';
import {
  ACTIVATE_SHELL_ACCOUNT_MUTATION,
  ActivateShellAccountMutationResponse,
} from '../gql/v2/mutation/ActivateShellAccountMutation';
import {
  FETCH_ORGANIZATIONS_BY_DOMAINS_QUERY,
  FetchOrganizationsByDomainsResponse,
} from '../gql/v2/query/FetchOrganizationsByDomains';
import {FETCH_ORGANIZATION_BY_INVITE_CODE} from 'src/gql/v2/query/FetchOrganizationByInviteCode';

const GEOGRAPHICAL_API_URL = 'https://ipapi.co/json/';
class PublicEndpoints {
  public static getPublicEndpoint() {
    return `${getApiEndpoint()}/graphql/public`;
  }

  public static getV2PublicEndpoint() {
    return `${getApiEndpoint()}/v2/graphql/public`;
  }

  public static getPublicHeaders() {
    return {
      'X-Request-ID': uuid(),
    };
  }

  public static async checkAddressAvailable({address, type}) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(CheckAddressAvailable),
          variables: {
            address,
            type,
          },
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async checkUsernameAvailable({username}) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(CheckUserNameAvailable),
          variables: {
            username,
          },
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async createOrganization(payload: CreateOrganizationPayload) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var graphql = JSON.stringify({
      query: print(CREATE_ORG_MUTATION),
      variables: payload,
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const result = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data = await result.json();
      console.log(data);
      return {
        data,
      };
    } catch (e) {
      console.error(e);
      return {
        data: false,
        error: e,
      };
    }
  }

  public static async createUser(payload: CreateUserPayload) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var graphql = JSON.stringify({
      query: print(CREATE_USER_MUTATION),
      variables: payload,
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const result = await fetch(this.getV2PublicEndpoint(), requestOptions);
      const data = await result.json();
      return {
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        data: false,
        error: error,
      };
    }
  }

  public static async createUserSTA(payload: CreateUserPayload) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var graphql = JSON.stringify({
      query: print(CREATE_STA_USER_MUTATION),
      variables: payload,
    });
    console.log(payload);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const result = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data = await result.json();
      return {
        data,
      };
    } catch (e) {
      console.error(e);
      return {
        data: false,
        error: e,
      };
    }
  }

  public static async requestValidationCodeForAddress(payload: RequestVerificationPayload) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(RequestVerificationPublicMutation),
          variables: payload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async verifyAddressValidationCode(payload: VerifyAddressValidationPayload) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(VERIFY_ADDRESS_VALIDATION_CODE),
          variables: payload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async requestValidationCodeForResetPassword(payload: RequestResetPasswordCodePayload) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(RequestResetPasswordTokenMutation),
          variables: payload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async verifyPasswordToken(payload: VerifyResetPasswordTokenPayload) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(VerifyResetPasswordMutation),
          variables: payload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async resetPassword(payload: ResetPasswordPayload) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(ResetPasswordMutation),
          variables: payload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async fetchOrganizationByInviteCode(code: string) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FetchOrganizationByInviteCode),
          variables: {code},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async fetchOrganizationByInviteCodeV2(code: string) {
    console.log(code);
    try {
      const result = await axios({
        url: this.getV2PublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FETCH_ORGANIZATION_BY_INVITE_CODE),
          variables: {code},
        },
      });
      const {data} = result.data;
      if (data.findOrganizationByInviteCode.id) {
        return {
          success: true,
          data,
        };
      } else {
        return {
          success: false,
          data,
        };
      }
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async fetchOrganizationByEmail(email: string) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FetchOrganizationByEmailQuery),
          variables: {email},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async unregisterPushNotification(payload) {
    try {
      const result = await axios({
        url: this.getPublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(UnregisterPushToken),
          variables: {...payload},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async getSSODomainsForUsername(email: string) {
    try {
      const result = await axios({
        url: this.getV2PublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(GetSSODomains),
          variables: {email},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
        error: result.data.errors,
      };
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async getSSOProfileForUser({email, url}: MemberIdentifier) {
    try {
      const result: AxiosResponse<FetchSSOProfileForUserResponse> = await axios({
        url: this.getV2PublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FETCH_SSO_PROFILE_FOR_USER),
          variables: {identifier: {email, url}},
        },
      });
      const {data} = result.data;
      return data;
    } catch (error) {
      console.error(error);
      return {
        error,
      };
    }
  }

  public static async listSSOProfiles() {
    try {
      const result: AxiosResponse<ListSSOProfilesQueryResponse> = await axios({
        url: this.getV2PublicEndpoint(),
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(ListSSOProfilesQuery),
        },
      });
      const {data} = result.data;
      return {
        data,
      };
    } catch (error: any) {
      console.error(error);
      return {
        error,
      };
    }
  }

  public static async fetchUserGeographicalLocation() {
    try {
      const result = await fetch(GEOGRAPHICAL_API_URL);
      const res = await result.json();
      return {
        data: res as UserGeoData,
        error: false,
      };
    } catch (error) {
      console.error(GEOGRAPHICAL_API_ERROR);
      return {
        data: null,
        error,
      };
    }
  }
  public static async fetchOrganizationByURL(organizationURL: string) {
    try {
      const result: AxiosResponse<FetchOrganizationByURLResponse> = await axios({
        url: 'https://api.staging.hypercare.com:3000/v2/graphql/public',
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FETCH_ORGANIZATION_BY_URL_QUERY),
          variables: {url: organizationURL},
        },
      });
      const {data} = result.data;
      return {
        data,
        error: null,
      };
    } catch (error) {
      console.error(error);
      return {
        data: null,
        error,
      };
    }
  }

  public static async fetchOrganizationByName(name: string, limit?: number) {
    const limitValue = limit ? limit : 5;
    try {
      const result: AxiosResponse<FetchOrganizationByNameResponse> = await axios({
        url: 'https://api.staging.hypercare.com:3000/v2/graphql/public',
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FETCH_ORGANIZATION_BY_NAME),
          variables: {name, limit: limitValue},
        },
      });
      const {data} = result.data;
      return {
        data,
        error: null,
      };
    } catch (error) {
      console.error(error);
      return {
        data: null,
        error,
      };
    }
  }
  public static async fetchBasicUser(email: string, organizationUrl: string) {
    try {
      const result: AxiosResponse<FetchBasicUserResponse> = await axios({
        url: 'https://api.staging.hypercare.com:3000/v2/graphql/public',
        method: 'post',
        headers: this.getPublicHeaders(),
        data: {
          query: print(FETCH_BASIC_USER_QUERY),
          variables: {email, organizationUrl},
        },
      });
      const {data} = result.data;
      return {
        data,
        error: null,
      };
    } catch (error) {
      console.error(error);
      return {
        data: null,
        error,
      };
    }
  }

  public static async activateShellAccountMutation(
    challengeId: string,
    organizationUrl: string,
    userDetails: ActivateShellAccountUserDetails,
  ) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const graphql = JSON.stringify({
      query: print(ACTIVATE_SHELL_ACCOUNT_MUTATION),
      variables: {
        challengeId,
        organizationUrl,
        userDetails,
      },
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const res = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data: ActivateShellAccountMutationResponse = await res.json();
      return data;
    } catch (e) {
      return {e};
    }
  }

  public static async fetchOrganizationsByDomains(domains: string[]) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const graphql = JSON.stringify({
      query: print(FETCH_ORGANIZATIONS_BY_DOMAINS_QUERY),
      variables: {
        domains,
      },
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const res = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data: FetchOrganizationsByDomainsResponse = await res.json();
      return data;
    } catch (e) {
      return {e};
    }
  }
}

export default PublicEndpoints;
