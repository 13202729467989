import * as React from 'react';
import client from 'src/apollo';
import {
  UserOrganizationSwitcherPayload,
  AuthProviderState,
  AuthPayload,
  LoginResponse,
  User,
  UserOrganizationScopeResult,
  AuthRegion,
  AuthSSOVendors,
  AuthPayloadDTO,
} from 'src/types';
import {AuthHelper} from 'src/auth';
import GetUserOrganizationsQuery from 'src/gql/query/GetUserOrganizations';
import store, {AppDispatch, RootState} from 'src/redux';
import {resetReduxStore} from 'src/redux/rootActions';
import {actions} from 'src/redux/actions/organization';
import {connect} from 'react-redux';
import getParsedAuthInfo, {updateLocalStorageUserInfo, getParsedAuthRegion} from 'src/utils/localStorageHandler';
import {toast} from 'react-toastify';
import EulaContainer from 'src/auth/EulaContainer';
import {clone, pick} from 'ramda';
import {Auth0Context, Auth0ContextInterface} from '@auth0/auth0-react';
import LocalNotificationManager from 'src/notifications/LocalNotificationManager';
import {ALLOWED_LOCAL_STORAGE_USER_KEY_LIST} from 'src/constants/user';
import throttle from 'lodash.throttle';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {
  AUTH_INFO,
  CURRENT_SELECTED_ORGANIZATION,
  DESIRED_ROUTE,
  HYPERCARE_REGION,
  IS_EXPIRED,
  ORGANIZATION_ACCOUNTS_DATA,
  REDIRECT_URI,
} from 'src/constants/storageKeys';
import {DEBUG} from 'src/constants/storageKeys';
import {LAST_ACTIVE_TIME} from 'src/constants/sessionTimeout';
import {CurrentSelectedOrganization, Organization} from '../microfrontend/types/login.types';
import {Account, AccountStatus, OrganizationAccountsCacheData, OrgLoginMethods, WebViewCallBacks} from '../types/sta';
import {localStorageService} from '../services/localStorageService';
import {callNative} from '../nativeBridge';
import {convertAuthResponseToAuthDTO} from './convertAuthResponseToAuthDTO';
import ApiHelper from 'src/api';
import {
  SAVED_ACCOUNTS_COOKIE,
  HYPERCARE_DOMAIN,
  setCookie,
  THIRTY_DAYS,
  gzipBase64,
  createOrganizationData,
  saveNotLoggedInAccountToCache,
  getCacheData,
  updateCacheData,
} from '../utils/sta/staUtils';
import {USER_BLOCKED} from '../constants/login';

const defaultUser: AuthPayload['user'] = {
  email: '',
  firstname: '',
  firstName: '',
  id: '',
  image: '',
  lastname: '',
  lastName: '',
  role: '',
  username: '',
  phoneNumber: '',
  profilePic: {
    url: '',
  },
};

interface ActionProps {
  authAlert: {tokenLastRefreshAt: number | null} | null;
  setCurrentOrganization: (organization: UserOrganizationSwitcherPayload) => void;
  auth0props: Auth0ContextInterface; // same useAuth0() hook to access authentication state
}

export const AuthContext = React.createContext<AuthProviderState>({
  isLoggedIn: false,
  login: (username: string, password: string): Promise<LoginResponse> | null => {
    return null;
  },
  loginViaSSO: (token: string, provider: AuthSSOVendors): Promise<LoginResponse> | null => {
    return null;
  },
  staSSOLogin: (
    token: string,
    provider: AuthSSOVendors,
    orgUrl: string,
    email: string,
  ): Promise<LoginResponse> | null => {
    return null;
  },
  STALogin: (currentOrg: Organization, payload: AuthPayloadDTO, email: string) => {
    return Promise.resolve(null);
  },
  logout: () => {},
  setAuthRegion: (region: AuthRegion) => {},
  authInfo: {user: defaultUser},
  updateAuthUserInfo: (user: Pick<User, 'id' | 'firstname' | 'username' | 'lastname'>) => {
    return null;
  },
  authCloneForOnboarding: null,
  auth0props: {
    getAccessTokenSilently: () => new Promise(() => {}),
    getAccessTokenWithPopup: () => new Promise(() => {}),
    getIdTokenClaims: () => new Promise(() => {}),
    loginWithRedirect: () => new Promise(() => {}),
    loginWithPopup: () => new Promise(() => {}),
    logout: () => new Promise(() => {}),
    isLoading: true,
    isAuthenticated: false,
  },
  authRegion: 'CA',
});

class AuthProvider extends React.Component<ActionProps> {
  public state: AuthProviderState;
  private inactiveTimeout: ReturnType<typeof setTimeout>;

  constructor(props: ActionProps) {
    super(props);
    const {auth0props} = props;
    const parsedAuthInfo = getParsedAuthInfo();
    const authRegion = getParsedAuthRegion();

    this.state = {
      isLoggedIn: parsedAuthInfo ? true : false,
      login: this.login,
      loginViaSSO: this.loginViaSSO,
      staSSOLogin: this.staSSOLogin,
      STALogin: this.STALogin,
      logout: this.logout,
      setAuthRegion: this.setAuthRegion,
      authInfo: parsedAuthInfo || {user: defaultUser},
      authCloneForOnboarding: null,
      auth0props: auth0props,
      authRegion: authRegion,
      updateAuthUserInfo: this.updateAuthUserInfo,
    };
  }

  public componentDidUpdate(prevProps: ActionProps) {
    const {auth0props, authAlert} = this.props;
    if (JSON.stringify(auth0props) !== JSON.stringify(prevProps.auth0props)) {
      this.setState({
        auth0props,
      });
    }
    if (authAlert !== prevProps.authAlert) {
      this.setState({
        authInfo: getParsedAuthInfo(),
      });
    }
  }

  public componentDidMount() {
    this.detectSessionExipration();
    this.setUpFocusBlurListener();
    window.addEventListener('storage', throttle(this.checkLocalStorageChanged, 2000));
  }

  public componentWillUnmount() {
    window.removeEventListener('storage', throttle(this.checkLocalStorageChanged, 2000));
  }

  // in result of network error of invalid token and location reload
  public detectSessionExipration = () => {
    const isResultingFromExpiredSession = sessionStorage.getItem(IS_EXPIRED);
    if (isResultingFromExpiredSession) {
      toast.warn(`Your session has expired, please login again`, {
        autoClose: false,
      });
    }
    sessionStorage.removeItem(IS_EXPIRED);
  };

  public setUpFocusBlurListener = () => {
    window.addEventListener('focus', () => {
      clearTimeout(this.inactiveTimeout);
    });

    window.addEventListener('blur', () => {
      if (this.state.isLoggedIn) {
        this.inactiveTimeout = setTimeout(() => {
          const previousFirstName = this.state.authInfo.user.firstname;
          const previousUsername = this.state.authInfo.user.username;

          this.logout();
          LocalNotificationManager.displayLogoutNotification();

          this.setState({
            ...this.state,
            authInfo: {
              ...this.state.authInfo,
              user: {...this.state.authInfo.user, firstname: previousFirstName, username: previousUsername},
            },
          });
        }, 28800000); // 8 hour inactive
      }
    });
  };

  /**
   * TODO: error flow handling of third party api
   * currently ignore all helper error (e.g. analytics/intercom) and still login
   */
  public login = async (username: string, password: string): Promise<LoginResponse> => {
    const result = await AuthHelper.fetchAuthInfo(username, password);
    await this.handleLogin(result);
    return result;
  };

  public STALogin = async (currentOrg: Organization, payload: AuthPayloadDTO, email: string): Promise<any> => {
    if (!payload) {
      this.setState({
        isLoggedIn: false,
      });
    }
    this.saveAccountToCache(currentOrg, payload, email);

    const redirect_uri = window.localStorage.getItem(REDIRECT_URI);

    if (redirect_uri) {
      window.localStorage.removeItem(REDIRECT_URI);
    } else {
      await this.onLoginSuccess(payload);
      this.setState({
        isLoggedIn: true,
        authInfo: payload,
      });

      window.routerHistory.push(`/messenger/home`);
    }
  };

  private saveAccountToCache = (currentOrg: Organization, payload: AuthPayloadDTO, email: string) => {
    let savedCacheData: OrganizationAccountsCacheData = localStorageService.getItem(ORGANIZATION_ACCOUNTS_DATA) ?? {
      savedOrganizations: [],
      selectedAccountUserId: '',
    };

    const incomingUserId = payload.user.id;
    const redirect_uri = window.localStorage.getItem(REDIRECT_URI);

    const existingOrgIndex = savedCacheData.savedOrganizations.findIndex(
      (org) => org.organization?.name === currentOrg?.name && org.user.id === incomingUserId,
    );

    if (existingOrgIndex !== -1) {
      let [existingOrgToModify] = savedCacheData.savedOrganizations.splice(existingOrgIndex, 1);

      savedCacheData.savedOrganizations.unshift({
        ...existingOrgToModify,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        accessTokenExpiresAt: payload.accessTokenExpiresAt,
      });
    } else {
      savedCacheData.savedOrganizations = [
        {
          ...payload,
          firstLogin: false,
          organization: currentOrg,
        },
        ...savedCacheData.savedOrganizations,
      ];
    }

    savedCacheData.selectedAccountUserId = payload.user.id || '';

    window.localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(savedCacheData));
    this.handleRedirect(redirect_uri, payload, savedCacheData.savedOrganizations);
  };

  private handleRedirect = (
    redirectUri: string | null,
    payload: AuthPayloadDTO,
    savedOrganizations: AuthPayloadDTO[],
  ): void => {
    if (!redirectUri) return;

    const isAdminRedirect = redirectUri.includes('admin') || redirectUri.includes('4000');

    if (isAdminRedirect) {
      const account = savedOrganizations.find((acc) => acc.user.id === payload.user.id);
      if (account) {
        const gzipBase64Data = gzipBase64(JSON.stringify(account));
        const encodedData = encodeURIComponent(gzipBase64Data);

        localStorage.removeItem(REDIRECT_URI);
        window.open(`${redirectUri}/?account=${encodedData}`, '_self', 'noopener,noreferrer');
        return;
      }
    }

    window.open(redirectUri, '_self');
  };

  public loginViaSSO = async (token: string, provider: AuthSSOVendors) => {
    const result = await AuthHelper.fetchSSOAccessToken(token, provider);
    await this.handleLogin(result);
    return result;
  };

  private getBasicUserInfo = async (email: string, orgUrl: string) => {
    const userInformation = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);
    console.log(userInformation, 'userInformation');
    if (userInformation?.data?.user) {
      return userInformation.data;
    }
  };

  public staSSOLogin = async (token: string, provider: AuthSSOVendors, orgUrl: string, email: string): Promise<any> => {
    const currentSelectedOrg = localStorageService.getItem<CurrentSelectedOrganization>(CURRENT_SELECTED_ORGANIZATION);

    if (!token || !currentSelectedOrg) {
      window.routerHistory.push('/login');
      this.setState({
        isLoggedIn: false,
      });
      return;
    }

    const getBasicUserInformationData = await this.getBasicUserInfo(email, orgUrl);

    if (getBasicUserInformationData?.user.__typename === 'UserBlocked') {
      return {
        error: USER_BLOCKED,
        success: false,
      };
    }

    if (getBasicUserInformationData?.user.__typename === 'PublicUser') {
      if (getBasicUserInformationData.user.memberStatus === AccountStatus.BLOCKED) {
        toast.error('Account has been removed');
        window.routerHistory.push('/login');
      } else {
        if (
          !(
            getBasicUserInformationData.user.memberStatus === AccountStatus.ACTIVE ||
            getBasicUserInformationData.user.memberStatus === AccountStatus.INACTIVE
          )
        ) {
          if (
            !(
              getBasicUserInformationData.user.loginScopes.includes(OrgLoginMethods.SSO) ||
              getBasicUserInformationData.user.loginScopes.includes(OrgLoginMethods.OTP)
            )
          ) {
            let cacheData = getCacheData();

            const newOrganizationData = {
              accessToken: null,
              accessTokenExpiresAt: null,
              refreshToken: null,
              organization: currentSelectedOrg,
              refreshTokenExpiresAt: null,
              scopeToken: '',
              sessionID: '',
              user: {
                id: getBasicUserInformationData.user.id,
                firstname: getBasicUserInformationData.user.firstName,
                lastname: getBasicUserInformationData.user.lastName,
                email: email,
                isAdmin: getBasicUserInformationData.user.isAdmin,
              },
            };

            cacheData.savedOrganizations.unshift(newOrganizationData);

            updateCacheData(cacheData);

            window.routerHistory.push('/login');
          }
        }
      }
      if (!orgUrl) {
        this.setState({
          isLoggedInd: false,
        });

        return {
          success: false,
          error: 'no org url',
        };
      }

      const otpGrantTypeResponse = await AuthHelper.exchangeSSOCodeForPartialAccessToken(token, provider);

      if (otpGrantTypeResponse?.data?.error === 'invalid_grant') {
        switch (otpGrantTypeResponse?.data?.reason) {
          case 'account_blocked':
            return {error: 'account_blocked'};
        }
      }

      if (otpGrantTypeResponse?.data?.error) {
        return {error: otpGrantTypeResponse?.data?.error || otpGrantTypeResponse.error};
      }

      if (otpGrantTypeResponse.data?.access_token) {
        const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

        if (otpExchangeToken?.data?.error || otpExchangeToken.error) {
          console.log('Issue occurred when exchanging email token for id token');
          window.routerHistory.push('/login');
          return {
            error: otpExchangeToken?.data?.error_description || otpExchangeToken.error,
            success: false,
          };
        }
        if (otpExchangeToken.data) {
          const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

          this.saveAccountToCache(currentSelectedOrg, convertResponseToAuth, convertResponseToAuth.user?.email || '');

          const redirect_uri = window.localStorage.getItem(REDIRECT_URI);

          if (redirect_uri) {
            window.localStorage.removeItem(REDIRECT_URI);

            return;
          } else {
            await this.onLoginSuccess(convertResponseToAuth);

            if (!window.location.href.includes('login')) window.location.assign(`/messenger/home`);

            this.setState({
              isLoggedIn: true,
              authInfo: otpExchangeToken.data,
            });
          }
        }
      }

      this.setState({
        isLoggedIn: true,
      });
    }

    const otpGrantTypeResponse = await AuthHelper.exchangeSSOCodeForPartialAccessToken(token, provider);

    if (otpGrantTypeResponse.error) {
      console.log('Issue occurred when exchanging sso token for email token');
      window.routerHistory.push('/login');
    }

    if (otpGrantTypeResponse.data?.access_token) {
      const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

      if (otpExchangeToken?.data?.error || otpExchangeToken.error) {
        console.log('Issue occurred when exchanging email token for id token');
        window.routerHistory.push('/login');
        return {
          error: otpExchangeToken?.data?.error_description || otpExchangeToken.error,
          success: false,
        };
      }
      if (otpExchangeToken.data) {
        const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

        this.saveAccountToCache(currentSelectedOrg, convertResponseToAuth, convertResponseToAuth.user?.email || '');

        const redirect_uri = window.localStorage.getItem(REDIRECT_URI);

        if (redirect_uri) {
          window.localStorage.removeItem(REDIRECT_URI);

          return;
        } else {
          await this.onLoginSuccess(convertResponseToAuth);

          if (!window.location.href.includes('login')) window.location.assign(`/messenger/home`);

          this.setState({
            isLoggedIn: true,
            authInfo: otpExchangeToken.data,
          });
        }
      }
    }

    console.log('Account has been removed from the organization');
    window.routerHistory.push('/login');

    this.setState({
      isLoggedIn: false,
    });
  };

  public updateAuthUserInfo = (user: Pick<User, 'id' | 'firstname' | 'username' | 'lastname'>) => {
    this.setState({
      authInfo: {
        ...this.state.authInfo,
        user,
      },
    });
    updateLocalStorageUserInfo(pick(ALLOWED_LOCAL_STORAGE_USER_KEY_LIST, user));
  };

  public setAuthRegion = (region: AuthRegion) => {
    localStorage.setItem(HYPERCARE_REGION, region);
    this.setState({
      authRegion: region,
    });
  };

  public logout = () => {
    const {auth0props} = this.props;
    AuthHelper.logout();
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.userLogout,
      params: {
        source: window.location.href,
      },
    });
    // provider state
    this.setState(
      {
        isLoggedIn: false,
        authCloneForOnboarding: null,
        authInfo: {user: defaultUser},
      },
      () => {
        sessionStorage.removeItem(DESIRED_ROUTE);
        sessionStorage.removeItem(DEBUG);
        localStorage.removeItem(LAST_ACTIVE_TIME);
        // redux
        // @ts-ignore
        store.dispatch(resetReduxStore());
        // auth0
        if (auth0props.isAuthenticated) {
          auth0props.logout({
            federated: true,
            returnTo: `${
              window.location.protocol +
              '//' +
              window.location.hostname +
              (window.location.port ? ':' + window.location.port : '')
            }/login`,
          });
        }
      },
    );
  };

  public setIsLoggedin = (authClone: AuthPayload) => {
    this.setState({
      isLoggedIn: true,
      authInfo: authClone,
    });
  };

  public render() {
    const {authCloneForOnboarding} = this.state;
    return (
      <React.Fragment>
        {authCloneForOnboarding && (
          <EulaContainer
            authClone={authCloneForOnboarding}
            clearAuthClone={this.clearOnboradingAuthClone}
            onLoginSuccess={this.onLoginSuccess}
            setIsLoggedin={this.setIsLoggedin}
          />
        )}
        <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>
      </React.Fragment>
    );
  }

  public handleLogin = async (result: LoginResponse) => {
    if (result.success) {
      const authPayload: AuthPayload = {...(result.data as AuthPayload)};
      // check for EULA
      const eulaStatus = authPayload.user.eulaStatus;
      if (!eulaStatus) {
        this.setState({
          authCloneForOnboarding: authPayload,
        });
        return {
          success: false,
          error: 'You must agree to the terms and conditions to continue',
        };
      }

      await this.onLoginSuccess(authPayload);

      this.setState({
        isLoggedIn: true,
        authInfo: result.data,
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
  };

  private onLoginSuccess = async (authPayload: AuthPayload | AuthPayloadDTO) => {
    const authClone: AuthPayload = clone(authPayload);
    // save to local storage
    AuthHelper.saveAuthToLocalStorage(authPayload);
    // fetch self organization data
    const {data} = await client.query<UserOrganizationScopeResult>({
      query: GetUserOrganizationsQuery,
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    });
    if (data) {
      authClone.user.email = data.me.email;
      // select and set the first organization at login
      const organizationScope = this.getOrganizationScope(data);
      if (organizationScope) {
        await AuthHelper.setAuthScope(organizationScope);
      }
    }
    // set up third party apis
    AuthHelper.setUpThirdParty(authClone);
    // TODO: timeout logout at expiration time
    // bug: https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
    // AuthHelper.setTimeoutLogout(authClone, this.logout);
  };

  private getOrganizationScope = (payload: UserOrganizationScopeResult) => {
    if (!payload.me.organizations[0]) return;
    const organizationScope = payload.me.organizations[0];
    this.props.setCurrentOrganization({
      type: organizationScope.__typename,
      image: organizationScope.image,
      name: organizationScope.name,
      organizationId: organizationScope.id,
      isAdmin: organizationScope.isAdmin,
    } as UserOrganizationSwitcherPayload);

    return organizationScope;
  };

  private clearOnboradingAuthClone = () => {
    return new Promise<void>((resolve) => {
      this.setState(
        {
          authCloneForOnboarding: null,
        },
        resolve,
      );
    });
  };

  // for sync multiple tabs thats not in focus
  private checkLocalStorageChanged = (e: StorageEvent) => {
    if (window.document.hasFocus()) return;
    // note: storage event trigger from cross tab only
    const {storageArea} = e;

    if (this.state.isLoggedIn && storageArea?.authInfo) {
      const {accessToken} = JSON.parse(storageArea.authInfo);
      if (accessToken && accessToken !== this.state.authInfo.accessToken) {
        window.location.reload();
      }
    }

    if (this.state.isLoggedIn && storageArea?.currentOrganization) {
      const reduxOrganization = store.getState().organization;
      const {organization} = JSON.parse(storageArea.currentOrganization);
      if (organization.organizationId && reduxOrganization.organizationId !== organization.organizationId) {
        this.props.setCurrentOrganization(organization);
        window.routerHistory.push(`/messenger/`);
        window.location.reload();
      }
    }
  };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setCurrentOrganization: (currentOrganization: UserOrganizationSwitcherPayload) => {
      dispatch(actions.setCurrentOrganization(currentOrganization));
    },
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    authAlert: state.auth,
  };
};

const ConnectedAuthProvider = connect(mapStateToProps, mapDispatchToProps)(AuthProvider);

export default (props) => (
  <Auth0Context.Consumer>
    {(auth0props: Auth0ContextInterface) => {
      return <ConnectedAuthProvider {...props} auth0props={auth0props} />;
    }}
  </Auth0Context.Consumer>
);
