import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {BannerMessage} from 'src/microfrontend/components/BannerMessage';
import ErrorIcon from 'src/svgs/ErrorIcon';
import CheckIcon from 'src/svgs/CheckIcon';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import {withStyles} from '@mui/styles';

interface IInviteUSerResponseModalProps {
  isOpen: boolean;
  handleOnCloseResponseModal: () => void;
  handleOnClick: () => void;
  title?: string;
  subTitle?: string;
  inviteResponse: any[];
  addressesInput: any[];
}

interface CategorizedInvites {
  sent: CategorizedInvite[];
  failed: CategorizedInvite[];
  pending: CategorizedInvite[];
}

interface CategorizedInvite {
  address: string;
  message: string;
  status: string;
}

const HeadingDiv = styled.div`
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const Container = styled.div`
  display: flex;
  padding: 12px 0px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftText = styled.span`
  color: #222;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  align-self: stretch;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const RightTextError = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: right;
  color: #ff3e55;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const RightTextSend = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: right;
  color: #00859a;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    display: 'flex',
    padding: '8px 12px',
    textAlign: 'center',
    borderRadius: '4px',
    background: '#FF3E55',
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.16)',
  },
  arrow: {
    color: '#FF3E55',
  },
}))(Tooltip);

const errorTranslateFunction = (error: string) => {
  switch (error) {
    case 'AddressInUse':
      return 'User exists';
    case 'AccountBlocked':
      return 'User removed';
    case 'pending_admin_approval':
    case 'InvitePendingAdminApproval':
      return 'Pending';
    case 'pending_user_acceptance':
    case 'OrganizationMember':
    case 'FullOrganizationMember':
      return 'Sent';
    default:
      return 'Unknown error';
  }
};

const mapEmailToStatus = (array1, array2) => {
  return array1.map((item1, index) => {
    const item2 = array2[index];
    if (item1.address === null && item2 && item2.address) {
      return {
        address: item2.address,
        message: item1.message,
        status: item1.__typename === 'UserInvite' ? item1.status : item1.__typename,
      };
    }

    return item1;
  });
};

export const InviteUserResponseModal = ({
  isOpen,
  handleOnCloseResponseModal,
  handleOnClick,
  inviteResponse,
  addressesInput,
}: IInviteUSerResponseModalProps) => {
  const [normalizedAccounts, setNormalizedAccounts] = useState<CategorizedInvite[]>([]);
  const [categorizeInvites, setCategorizeInvites] = useState<CategorizedInvites>();
  const failedCount = categorizeInvites?.failed.length;
  const sentCount = categorizeInvites?.sent.length;
  const pendingCount = categorizeInvites?.pending.length;
  const totalCount = inviteResponse.length;
  const finalAccounts = mapEmailToStatus(normalizedAccounts, addressesInput);
  useEffect(() => {
    const normalizeAccounts = () => {
      const normalized = inviteResponse.map((account) => ({
        status: account.__typename === 'UserInvite' ? account.status : account.__typename,
        message: account.message || null,
        __typename: account.__typename,
        address:
          account.address ||
          (account?.user?.addresses && account?.user?.addresses?.[0] && account?.user?.addresses?.[0]?.address) ||
          null,
      }));
      setNormalizedAccounts(normalized);
    };

    if (inviteResponse.length > 0) {
      normalizeAccounts();
    }
  }, [inviteResponse]);

  useEffect(() => {
    const categorizeInvites = () => {
      const categorized: CategorizedInvites = {
        sent: [],
        failed: [],
        pending: [],
      };

      finalAccounts.forEach((result) => {
        const categorizedInvite: CategorizedInvite = {
          address: result.address,
          status: result.status,
          message: result.message,
        };
        switch (result.status) {
          case 'pending_user_acceptance':
          case 'OrganizationMember':
          case 'FullOrganizationMember':
            categorized.sent.push(categorizedInvite);
            break;
          case 'AddressInUse':
          case 'AccountBlocked':
          case 'Unknown error':
            categorized.failed.push(categorizedInvite);
            break;
          case 'pending_admin_approval':
          case 'InvitePendingAdminApproval':
            categorized.pending.push(categorizedInvite);
            break;
        }
      });

      setCategorizeInvites(categorized);
    };
    if (finalAccounts.length > 0) {
      categorizeInvites();
    }
  }, [normalizedAccounts]);

  const getBannerMessage = () => {
    return (
      <div style={{paddingBottom: '16px'}}>
        {failedCount === totalCount ? (
          <BannerMessage
            type="error"
            description={`${failedCount}/${totalCount} invite requests failed to be sent. Contact admin for more information.`}
          />
        ) : sentCount === totalCount ? (
          <BannerMessage
            type="success"
            description={`${sentCount}/${totalCount} invite requests have been successfully sent.`}
          />
        ) : pendingCount === totalCount ? (
          <BannerMessage
            type="success"
            description={`${pendingCount}/${totalCount} invite requests are sent for admin approval. You will be informed via email once the decision is made.`}
          />
        ) : failedCount ? (
          <BannerMessage
            type="warning"
            description={`${failedCount}/${totalCount} invite requests failed to be sent. Contact admin for more information.`}
          />
        ) : sentCount && pendingCount ? (
          sentCount + pendingCount === totalCount ? (
            <BannerMessage
              type="success"
              description={`${
                sentCount + pendingCount
              }/${totalCount} invite requests are sent for admin approval. You will be informed via email once the decision is made.`}
            />
          ) : null
        ) : null}
      </div>
    );
  };

  const getFailedAddresses = () => {
    if (failedCount && failedCount > 0)
      return (
        <div>
          <HeadingDiv>FAILED ({failedCount})</HeadingDiv>
          {categorizeInvites?.failed.map((invite) => (
            <>
              <Container>
                <LeftText>{invite?.address}</LeftText>
                <CustomTooltip arrow title={invite.message} placement="top" PopperProps={{disablePortal: true}}>
                  <RightTextError>
                    <ErrorIcon width={14} height={14} />
                    {errorTranslateFunction(invite?.status)}
                  </RightTextError>
                </CustomTooltip>
              </Container>
              <Divider />
            </>
          ))}
        </div>
      );
  };

  const getSentAddresses = () => {
    if (sentCount && sentCount > 0)
      return (
        <div style={{paddingTop: '10px'}}>
          <HeadingDiv>SENT ({sentCount})</HeadingDiv>
          {categorizeInvites?.sent.map((invite) => (
            <>
              <Container>
                <LeftText>{invite?.address}</LeftText>
                <RightTextSend>
                  <CheckIcon width={14} height={14} stroke={'#00859a'} />
                  {errorTranslateFunction(invite?.status)}
                </RightTextSend>
              </Container>
              <Divider />
            </>
          ))}
        </div>
      );
  };

  const getPendingAddresses = () => {
    if (pendingCount && pendingCount > 0)
      return (
        <div style={{paddingTop: '10px'}}>
          <HeadingDiv>SENT FOR ADMIN APPROVAL ({pendingCount})</HeadingDiv>
          {categorizeInvites?.pending.map((invite) => (
            <>
              <Container>
                <LeftText>{invite?.address}</LeftText>
                <RightTextSend>
                  <CheckIcon width={14} height={14} stroke={'#00859a'} />
                  {errorTranslateFunction(invite?.status)}
                </RightTextSend>
              </Container>
              <Divider />
            </>
          ))}
        </div>
      );
  };
  return (
    <AlertModal
      width="sm"
      title={'Invite requests processed'}
      titleFontSize="21px"
      modalContent={
        <div>
          {getBannerMessage()}
          {getFailedAddresses()}
          {getSentAddresses()}
          {getPendingAddresses()}
        </div>
      }
      closeAlertModal={() => handleOnCloseResponseModal()}
      isAlertModalVisible={isOpen}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: 'Continue inviting',
          onClickHandler: () => handleOnClick(),
          id: 'continue-inviting-btn',
        },
        {
          type: 'primary',
          buttonLabel: 'Done',
          onClickHandler: () => handleOnCloseResponseModal(),
          id: 'close-btn',
        },
      ]}
    />
  );
};
