import React from 'react';
import SignupPage from '../signup/SignupPage';

interface IJoinOrgWithCodeProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  currentStep?: number;
  content?: React.ReactNode;
}

const JoinOrgWithCode: React.FC<IJoinOrgWithCodeProps> = ({onBackButtonClick, content, currentStep = 1}) => {
  return (
    <SignupPage
      currentStep={currentStep}
      isDisabled={false}
      title="Join existing organization with a code"
      description="Contact your Admins or colleagues to obtain this organization code."
      onBackButtonClick={onBackButtonClick}
      content={content}
    />
  );
};

export default JoinOrgWithCode;
