import * as React from 'react';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {MESSENGER, LOCATING, SCHEDULING, CONTACTS} from 'src/constants/routerPathName';
import {Tooltip} from '@mui/material';
import {StyledKbd} from 'src/styles/styled-components/StyledKBD';
import {UserOrganizationSwitcherPayload} from 'src/types';
import {useLocation} from 'react-router-dom';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {GetOrganizationScope} from 'src/utils/organizationHelper/getOrganizationScope';
import {useIsAuthorizedAuditLogs} from '../../../AuditLogs/hooks/useIsAuthorizedAuditLogs';
import {getAdminWebAppUrl, getCurrentLoggedInAccount, gzipBase64} from '../../../../utils/sta/staUtils';
import {ORGANIZATION_ACCOUNTS_DATA, REDIRECT_URI} from '../../../../constants/storageKeys';
import {localStorageService} from '../../../../services/localStorageService';
import {OrganizationAccountsCacheData} from '../../../../types/sta';

// TODO: messages badge
// in socket: when receiving socket and user is not currently in messenger/actual_chatid or messenger/home
// greyout when in the page
// write a localQuery of this boolean has messages
// ui: https://material-ui.com/components/badges/#simple-badges

const NavigationWrapper = styled.div`
  margin-right: 30px;
  min-width: max-content;
`;

const StyledTooltipHolder = styled.div`
  padding: 3px;
`;

const StyledNavLink = styled(Link)<{selected: boolean}>`
  color: ${(props) => (props.selected ? props.theme.colors.watermelon : props.theme.colors.labelGrey)};
  text-decoration: none;
  font-family: Nunito;
  font-weight: bolder;
`;

const StyledNavAnchorText = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.watermelon};
`;

const StyledNavAnchor = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 16px;
  width: 150px;
  height: 36px;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.watermelon};
  border-radius: 4px;
`;

export const RedTag = styled.div`
  color: ${(props) => props.theme.colors.errorRed};
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 20px;
  margin-left: 6px;
  padding: 2px 8px;
  border-radius: 20px;
  background: #ffd8dd;
`;

interface Props {
  currentOrganization: UserOrganizationSwitcherPayload;
}

const RightSectionItems = ({currentOrganization}: Props) => {
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const adminPortalURL =
    process.env.REACT_APP_ENV === 'production' ? 'https://admin.hypercare.com' : 'https://admin.staging.hypercare.com';

  const {isAuditLogsEnabled} = useIsAuthorizedAuditLogs();

  let location = useLocation();

  const handleMenuMixpanelEvents = (event) => {
    AnalyticsManager.applyAnalytics({
      eventName: event,
    });
  };

  const data = GetOrganizationScope();

  const isAdmin = data?.me?.scopes?.some((elem) => elem.status === 'admin');

  if (!currentOrganization.organizationId) return null;

  const handleAdminPortalClick = () => {
    const currentLoggedInAccount = getCurrentLoggedInAccount();

    if (currentLoggedInAccount) {
      const gzipBase64Data = gzipBase64(JSON.stringify(currentLoggedInAccount));
      const encodedData = encodeURIComponent(gzipBase64Data);

      window.open(`${getAdminWebAppUrl()}/login?account=${encodedData}`, '_blank', 'noopener,noreferrer');
      return;
    }
  };

  return (
    <React.Fragment>
      <NavigationWrapper>
        <Tooltip
          placement="bottom"
          title={
            <StyledTooltipHolder>
              Schedule <StyledKbd>g</StyledKbd> <StyledKbd>s</StyledKbd>
            </StyledTooltipHolder>
          }
          aria-label="Scheduling"
        >
          <StyledNavLink
            onClick={() => handleMenuMixpanelEvents(EVENTS.scheduleTabPressed)}
            selected={location.pathname.includes(SCHEDULING)}
            to={`/${SCHEDULING}`}
          >
            Schedule
          </StyledNavLink>
        </Tooltip>
      </NavigationWrapper>

      <NavigationWrapper>
        <Tooltip
          placement="bottom"
          title={
            <StyledTooltipHolder>
              Messages <StyledKbd>g</StyledKbd> <StyledKbd>m</StyledKbd>
            </StyledTooltipHolder>
          }
          aria-label="Messages"
        >
          <StyledNavLink
            onClick={() => handleMenuMixpanelEvents(EVENTS.messagesTabPressed)}
            to={`/${MESSENGER}/home`}
            selected={location.pathname.includes(MESSENGER)}
          >
            Messages
          </StyledNavLink>
        </Tooltip>
      </NavigationWrapper>
      <NavigationWrapper>
        <Tooltip
          placement="bottom"
          title={
            <StyledTooltipHolder>
              On-call <StyledKbd>g</StyledKbd> <StyledKbd>o</StyledKbd>
            </StyledTooltipHolder>
          }
          aria-label="on-call"
        >
          <StyledNavLink
            onClick={() => handleMenuMixpanelEvents(EVENTS.onCallTabPressed)}
            to={`/${LOCATING}`}
            selected={location.pathname.includes(LOCATING)}
          >
            On-call
          </StyledNavLink>
        </Tooltip>
      </NavigationWrapper>

      <NavigationWrapper>
        <Tooltip
          placement="bottom"
          title={
            <StyledTooltipHolder>
              Contacts <StyledKbd>g</StyledKbd> <StyledKbd>c</StyledKbd>
            </StyledTooltipHolder>
          }
          aria-label="Locating"
        >
          <StyledNavLink
            onClick={() => handleMenuMixpanelEvents(EVENTS.contactsTabPressed)}
            to={`/${CONTACTS}`}
            selected={location.pathname.includes(CONTACTS)}
          >
            Contacts
          </StyledNavLink>
        </Tooltip>
      </NavigationWrapper>
      {isAuditLogsEnabled && (
        <NavigationWrapper>
          <StyledNavLink
            to={`/audit-logs`}
            selected={location.pathname.includes('audit-logs')}
            style={{display: 'flex', alignItems: 'center'}}
          >
            Audit Logs
            <RedTag>NEW</RedTag>
          </StyledNavLink>
        </NavigationWrapper>
      )}
      {isAdmin && (
        <NavigationWrapper>
          <StyledNavAnchor onClick={handleAdminPortalClick}>
            <StyledNavAnchorText>Admin Portal</StyledNavAnchorText>
          </StyledNavAnchor>
        </NavigationWrapper>
      )}
    </React.Fragment>
  );
};

export default RightSectionItems;
