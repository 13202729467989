import gql from 'graphql-tag';

export interface IInviteResponse {
  address: string;
  __typename: string;
  message: string;
}

export const INVITE_USERS_MUTATION = gql`
  mutation InviteUsersSTA($invitees: [InviteUserDetails!]!) {
    organizationMutation {
      inviteUsers(invitees: $invitees) {
        ... on InviteUsersResponse {
          invitees {
            __typename
            ... on Error {
              message
            }
            ... on UserInvite {
              id
              status
              user {
                ...OrganizationMemberFragment
              }
              resolvedBy {
                ...OrganizationMemberFragment
              }
            }
            ... on AddressInUse {
              address
            }
            ... on AccountBlocked {
              address
            }
          }
        }
      }
    }
  }

  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstName
    lastName
    role
    memberStatus
    addresses {
      address
      type
      access
    }
  }
`;
