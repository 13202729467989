import React from 'react';
import styled from '@emotion/styled';
import {ToastContainer, ToastContainerProps} from 'react-toastify';

export const WrappedToastContainer = ({className, ...rest}: ToastContainerProps & {className?: string}) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

// https://github.com/fkhadra/react-toastify/issues/342
// https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components

export default styled(WrappedToastContainer)`
  .Toastify__toast-container {
    width: 650px;
    max-width: 100vw !important;

    @media only screen and (max-width: 600px) {
      width: 95%;
      max-width: none;
    }
  }
  .Toastify__toast {
    padding: 8px 14px;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .Toastify__toast-body {
    margin: 0;
  }
  .Toastify__toast--info {
    background-color: ${(props) => props.theme.colors.chatTeal};
    color: white;
  }
  .Toastify__close-button {
    font-size: 1em;
    margin-left: 0.5em;
    align-self: center;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .Toastify__toast-container--top-center {
    transform: translateX(-50%);
    margin-left: unset;

    @media only screen and (max-width: 600px) {
      left: 50%;
      top: 1%;
    }
  }
`;
