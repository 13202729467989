import React from 'react';
import {
  CHANGE_PASSWORD_MUTATION,
  ChangePasswordMutationResponse,
  ChangePasswordRequestVariables,
} from '../../../gql/v2/mutation/ChangePasswordMutation';
import {print} from 'graphql/language/printer';
import ApiHelper from '../../../api';

export const ChangePasswordRepository = () => {
  const changePassword = async ({currentPassword, newPassword}: ChangePasswordRequestVariables) => {
    const res = await ApiHelper.PrivateEndpoints.initiateChangePassword({currentPassword, newPassword});
    return res;
  };

  return {changePassword};
};
