import gql from 'graphql-tag';
import {Address} from 'src/types/sta';

export type UpdateAddressAccessResponse = {
  data: {
    selfMutation: {
      address: {
        updateAccess: Address;
      };
    };
  };
};

export const UPDATE_ADDRESS_ACCESS_MUTATION = gql`
  mutation UpdateAddressAccess($address: String!, $access: AccessType!) {
    selfMutation {
      address(address: $address) {
        ... on AddressNotFound {
          message
        }
        ... on AddressMutation {
          updateAccess(access: $access) {
            __typename
            ... on Address {
              ...AddressFragment
            }
          }
        }
      }
    }
  }

  fragment AddressFragment on Address {
    __typename
    id
    address
    type
    access
    label
  }
`;
