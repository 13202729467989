import {ApolloLink, Observable, Operation} from 'apollo-link';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import moment from 'moment-timezone';
import getGqlEndpoint from 'src/apollo/getGqlEndpoint';
import ApiHelper from 'src/api';
import {v4 as uuid} from 'uuid';
import {GQL_V2_OPERATIONS} from 'src/constants/v2OperationName';

// doc:
// https://www.apollographql.com/docs/react/advanced/boost-migration#advanced-migration
// https://www.apollographql.com/docs/react/networking/network-layer/#afterware

const request = async (operation: Operation) => {
  const parsedAuthInfo = getParsedAuthInfo();
  const {accessToken = ''} = parsedAuthInfo || {};

  // Instead of using `operation.variables` to set a fixed auth token for all requests,
  // the newer ApolloGraphQL versions allow passing the auth token directly enabling token customization per request.

  const getAccessToken = () => {
    const customAccessToken = operation.variables.customAccessToken;
    if (customAccessToken) {
      return `Bearer ${customAccessToken}`;
    }

    if (accessToken) {
      return `Bearer ${accessToken}`;
    }

    return '';
  };

  const ApiURL = GQL_V2_OPERATIONS[operation.operationName]
    ? ApiHelper.PrivateEndpoints.getPrivateEndpointV2()
    : getGqlEndpoint();

  operation.setContext({
    uri: ApiURL,
    headers: {
      'Cache-Control': 'no-cache,no-store,must-revalidate,private',
      Pragma: 'no-cache',
      Expires: 0,
      'hc-timezone': moment.tz.guess(),
      'X-Timezone': moment.tz.guess(),
      'X-Request-ID': uuid(),
      Authorization: getAccessToken(),
    },
    start: new Date().getTime(),
  });

  delete operation.variables.customAccessToken;
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((op: Operation) => request(op))
        .then(() => {
          handle =
            forward &&
            forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export default requestLink;
