import * as React from 'react';
import * as io from 'socket.io-client';
import {ApolloConsumer} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {AuthContext} from 'src/auth/AuthProvider';
import {AuthPayload} from 'src/types';
import getWsEndpoint from 'src/utils/getWsEndpoint';
import {DISCONNECTED, CONNECTED, RECONNECTING} from 'src/constants/connectionStatuses';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import ApolloSocketIOHandlerWithFirebase from 'src/api/ApolloSocketIOHandlerWithFirebase';

interface Props {
  client: ApolloClient<any>;
  authInfo: AuthPayload;
  flags: any;
}

class ApolloSocketIOListener extends React.Component<Props> {
  public socket: SocketIOClient.Socket | undefined;
  public handler: ApolloSocketIOHandlerWithFirebase;
  public manager: SocketIOClient.Manager;

  private socketRefetchDelayTimeout;

  public componentDidUpdate() {
    if (!this.socket) this.setupSocketIO();
    if ((!this.props.authInfo || !this.props.authInfo.user.id) && this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }

  public componentDidMount() {
    if (!this.socket) {
      this.setupSocketIO();
      window.addEventListener('online', this.goOnline);
      window.addEventListener('offline', this.goOffline);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('online', this.goOnline);
    window.removeEventListener('offline', this.goOffline);
    clearTimeout(this.socketRefetchDelayTimeout);
  }

  public render() {
    return this.props.children;
  }

  private refetchConnectionStatusHandler = () => {
    if (this.socket && this.socket.connected && navigator.onLine) {
      this.handler.handleConnectionStatus(CONNECTED);
    } else {
      this.handler.handleConnectionStatus(DISCONNECTED);
    }
  };

  public tryRefetch = async () => {
    const min = 1 * 1000;
    const max = 120 * 1000;
    //generates a random number between min & max inclusively
    const randNum = Math.floor(Math.random() * (max - min + 1)) + min;
    this.socketRefetchDelayTimeout = setTimeout(async () => {
      await this.props.client.reFetchObservableQueries();
    }, randNum);
  };

  private goOnline = () => {
    /*
     * TODO: get back to this open issue https://github.com/apollographql/apollo-client/issues/3766
     * offline -> reload -> reconnect - error: Store reset while query was in flight(not completed in link chain)
     * need abort all pending query before resetStore, previous workaround is set and clear timeout
     * current workaround is https://github.com/apollographql/apollo-client/issues/3555 by @dallonf
     * Note this will still potentially firing error in the query rendering at layouts
     * also https://github.com/socketio/socket.io/issues/2924
     * when inactive, browser tends to throttle the connectivity
     * to check if broswer is active:
     * https://stackoverflow.com/questions/1760250/how-to-tell-if-browser-tab-is-active
     * e.g. can setTimeout to determine the needs of update local client
     */
    if (this.socket && this.socket.connected) {
      clearTimeout(this.socketRefetchDelayTimeout);
      this.refetchConnectionStatusHandler();
      this.tryRefetch();
    }
  };

  private goOffline = () => {
    if (this.handler) this.handler.handleConnectionStatus(DISCONNECTED);
  };

  private setupSocketIO() {
    const {authInfo, client} = this.props;
    if (authInfo && authInfo.accessToken) {
      this.handler = new ApolloSocketIOHandlerWithFirebase(client, authInfo);

      this.manager = new io.Manager(getWsEndpoint(this.props.flags), {
        transports: ['websocket', 'polling'],
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${authInfo.accessToken}`,
            },
          },
        },
      });
      this.socket = this.manager.socket('/');

      // TODO: for mvp just refetch those queries when receive such socket event
      this.socket.on('escalation', (payload) => {
        // e.g. activatedLevel changes from null when just activated
        this.handler.handleRefetchActiveEscalation(payload);
      });

      this.socket.on('incoming_message', (message) => {
        this.handler.handleIncomingMessage(message);
      });
      // TODO: update chat by admin promoted and admin demoted
      this.socket.on('member_left', (payload) => {
        this.handler.handleMemberLeft(payload);
      });
      this.socket.on('members_added', (payload) => {
        this.handler.handleMemberAdded(payload);
      });
      this.socket.on('new_chat', (chat) => {
        this.handler.handleNewChat(chat);
      });
      this.socket.on('message_read', (readReceipt) => {
        this.handler.addToReadReceipts(readReceipt);
      });
      this.socket.on('connect', () => {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.socketConnected,
        });
        this.socket?.emit('authentication', `Bearer ${getParsedAuthInfo()?.accessToken}`);
        this.handler.handleConnectionStatus(CONNECTED);
      });
      this.socket.on('disconnect', (reason: string) => {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.socketDisconnected,
        });
        this.handler.handleConnectionStatus(DISCONNECTED);
        console.error(reason);
        if (reason === 'io server disconnect' && this.socket) {
          // the disconnection was initiated by the server will need to reconnect manually
          this.socket.connect();
        }
      });
      this.socket.on('reconnect', () => {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.socketReconnected,
        });
        this.tryRefetch();
      });
      this.socket.on('reconnecting', () => {
        this.handler.handleConnectionStatus(RECONNECTING);
      });
    }
  }
}

const NewApolloSocketIOListener = withLDConsumer()(ApolloSocketIOListener);

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <ApolloConsumer>
        {(client) => <NewApolloSocketIOListener {...props} authInfo={authInfo} client={client} />}
      </ApolloConsumer>
    )}
  </AuthContext.Consumer>
);
