import React from 'react';
import ApiHelper from '../../../../api';
import {
  CURRENT_SELECTED_ORGANIZATION,
  ORG_URL,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../../../../constants/storageKeys';
import {AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {AccountStatus, OrganizationAccountsCacheData, OrgLoginMethods} from '../../../../types/sta';
import {
  ACCOUNT_BLOCKED_INPUT_ERROR,
  ACCOUNT_REMOVED_INPUT_ERROR,
  GENERIC_PASSWORD_INPUT_ERROR,
  TOO_MANY_CHALLENGES,
} from '../../../../constants/networkError';
import AuthHelper from '../../../../auth/authHelper';
import authHelper from '../../../../auth/authHelper';
import {convertAuthResponseToAuthDTO} from '../../../../auth/convertAuthResponseToAuthDTO';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';
import {CurrentSelectedOrganization} from '../../../../microfrontend/types/login.types';
import {EMAIL} from 'src/constants/storageKeys';
import {localStorageService} from '../../../../services/localStorageService';
import {
  MEMBER_BLOCKED,
  NO_MATCHED_ACCOUNT,
  NO_SUPPORTED_LOGIN_METHODS,
  USE_CORPORATE_EMAIL,
  USER_BLOCKED,
} from '../../../../constants/login';
import {getAllLoggedInAccounts} from '../../../../utils/sta/staUtils';
import {UserRepository} from '../../../../data/repository/UserRepository';

export const OrganizationViewModel = () => {
  const {fetchUnreadChatCountForUser} = UserRepository();
  const fetchOrganizationByURL = async (orgURL: string) => {
    const result = await ApiHelper.PublicEndpoints.fetchOrganizationByURL(orgURL);

    if (result.data?.findOrganizationByURL.__typename === 'OrganizationNotFound') {
      return {data: null, error: 'Organization Not Found'};
    }
    if (result) return {data: result.data, error: false};

    return {data: null, error: 'Network error'};
  };

  const fetchOrganizationByName = async (name: string) => {
    const result = await ApiHelper.PublicEndpoints.fetchOrganizationByName(name);

    if (result) return result;
  };

  const storeUserDetailsInLocalStorage = (email: string, org: CurrentSelectedOrganization) => {
    localStorage.setItem(ORG_URL, org.url);
    localStorage.setItem(CURRENT_SELECTED_ORGANIZATION, JSON.stringify(org));
    localStorage.setItem(EMAIL, email);
    sessionStorage.setItem('email', email);
  };

  const handleSSOLogin = async (ssoProfile: any) => {
    localStorage.setItem(SSO_VENDOR, ssoProfile.ssoVendor);
    localStorage.setItem('flowStep', 'findMyOrg');

    if (ssoProfile.ssoVendor === AuthSSOVendors.WORKOS) {
      await getSSOAuthorizationUrl(ssoProfile.auth0Id, ssoProfile.ssoVendor);
    } else {
      return {auth0Id: ssoProfile.auth0Id, address: ''};
    }
  };

  const handleOTPLogin = async (email: string) => {
    const startRequestOTPFlow = await requestAddressOTP({address: email, type: 'email'});

    console.log(startRequestOTPFlow, 'start');

    if (typeof startRequestOTPFlow === 'string') {
      return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
    } else {
      return startRequestOTPFlow;
    }
  };

  const handlePasswordOrOTPLogin = async (
    basicUser: any,
    checkOTP: boolean,
    checkPassword: boolean,
    email: string,
    orgUrl: string,
    orgName: string,
    orgId: number,
  ) => {
    if (basicUser.memberStatus === AccountStatus.INACTIVE) {
      const challengeIdres = await requestAddressOTP({address: email, type: 'email'});
      if (typeof challengeIdres === 'string') {
        console.log('organizationViewModel', challengeIdres);
        const challengeId: string = challengeIdres;
        window.routerHistory.push(
          `/signup?email=${email?.replace(
            '+',
            '%2b',
          )}&challengeId=${challengeId}&orgUrl=${orgUrl}&step=2&orgId=${orgId}&activateShellAccountFlow=true&orgName=${orgName}`,
        );
      } else {
        if (challengeIdres.error) {
          return {
            error: 'Too many challenges, please wait before starting another challenge',
          };
        } else {
          return {
            error: 'Failed to request validation code, please check your internet connection and try again',
          };
        }
      }
    } else {
      if (checkOTP && checkPassword) {
        return {screen: OrgLoginMethods.PASSWORD, isMethodSwitchable: true};
      }
      if (checkPassword) {
        return {screen: OrgLoginMethods.PASSWORD};
      }
      if (checkOTP) {
        return await handleOTPLogin(email);
      }
    }

    return {error: USE_CORPORATE_EMAIL};
  };

  const handleInactiveOrNoMatchAccount = async (
    email: string,
    emailDomain: string,
    currentSelectedOrg: CurrentSelectedOrganization,
  ) => {
    const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({
      domain: emailDomain,
    });

    if ('error' in getOrgByDomain) {
      return {error: NO_MATCHED_ACCOUNT};
    }

    if (getOrgByDomain.data.organizationForDomain?.id === currentSelectedOrg?.id) {
      const challengeIdRes = await requestAddressOTP({address: email, type: 'email'});
      if (typeof challengeIdRes === 'string') {
        const challengeId: string = challengeIdRes;
        window.routerHistory.push(`/signup?email=${email?.replace('+', '%2b')}&challengeId=${challengeId}&step=2`);
      } else {
        if (challengeIdRes.error) {
          return {
            error: 'Too many challenges, please wait before starting another challenge',
          };
        } else {
          return {
            error: 'Failed to request validation code, please check your internet connection and try again',
          };
        }
      }
    } else {
      return {
        error: NO_MATCHED_ACCOUNT,
      };
    }
  };
  const submitEmailAddress = async (email: string, currentSelectedOrg: CurrentSelectedOrganization) => {
    const currentSavedAccountData =
      localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    storeUserDetailsInLocalStorage(email, currentSelectedOrg);
    const basicUser = await getBasicUser(email, currentSelectedOrg.url);
    const emailDomain = email.split('@')[1];

    if (basicUser && 'error' in basicUser) {
      return {error: basicUser.error};
    }

    if (basicUser?.__typename === USER_BLOCKED || basicUser?.__typename === MEMBER_BLOCKED) {
      return {error: USER_BLOCKED};
    }

    console.log('1');

    const listSSOProfiles = await ApiHelper.PublicEndpoints.listSSOProfiles();

    let checkIfSSOEmailOfSelectedOrg;

    if ('data' in listSSOProfiles) {
      checkIfSSOEmailOfSelectedOrg = listSSOProfiles?.data?.listSSOProfiles.find(
        (profile) => profile.domain === emailDomain && currentSelectedOrg.id === profile.organization.id,
      );
    }

    console.log('2');

    console.log(basicUser, 'basicuser');

    if (basicUser?.id) {
      const isAccountLoggedIn = currentSavedAccountData?.savedOrganizations.find(
        (org) => org.user.id === basicUser.id && org.accessToken,
      );

      if (isAccountLoggedIn?.accessToken) {
        return {
          screen: 'login',
          data: isAccountLoggedIn,
        };
      }

      const checkSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
      const checkOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
      const checkPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

      if (basicUser.memberStatus === AccountStatus.BLOCKED) {
        return {error: USER_BLOCKED};
      }

      if (checkIfSSOEmailOfSelectedOrg) {
        if (basicUser.memberStatus === AccountStatus.ACTIVE || basicUser.memberStatus === AccountStatus.INACTIVE) {
          if (basicUser.loginScopes.includes(OrgLoginMethods.SSO)) {
            return await handleSSOLogin(checkIfSSOEmailOfSelectedOrg);
          } else {
            if (
              basicUser.loginScopes.includes(OrgLoginMethods.OTP) ||
              basicUser.loginScopes.includes(OrgLoginMethods.PASSWORD)
            ) {
              return await handlePasswordOrOTPLogin(
                basicUser,
                checkOTPScope,
                checkPasswordScope,
                email,
                currentSelectedOrg.url,
                currentSelectedOrg.name,
                currentSelectedOrg.id,
              );
            } else {
              return {
                error: NO_SUPPORTED_LOGIN_METHODS,
              };
            }
          }
        } else {
          return await handleSSOLogin(checkIfSSOEmailOfSelectedOrg);
        }
      }

      console.log('2');

      if (basicUser.memberStatus === AccountStatus.ACTIVE || basicUser.memberStatus === AccountStatus.INACTIVE) {
        if (
          basicUser.loginScopes.includes(OrgLoginMethods.OTP) ||
          basicUser.loginScopes.includes(OrgLoginMethods.PASSWORD)
        ) {
          return await handlePasswordOrOTPLogin(
            basicUser,
            checkOTPScope,
            checkPasswordScope,
            email,
            currentSelectedOrg.url,
            currentSelectedOrg.name,
            currentSelectedOrg.id,
          );
        } else {
          return {
            error: USE_CORPORATE_EMAIL,
          };
        }
      } else {
        return handleInactiveOrNoMatchAccount(email, emailDomain, currentSelectedOrg);
      }
    }

    if (checkIfSSOEmailOfSelectedOrg) {
      return await handleSSOLogin(checkIfSSOEmailOfSelectedOrg);
    } else {
      return handleInactiveOrNoMatchAccount(email, emailDomain, currentSelectedOrg);
    }
  };

  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res && 'data' in res) {
      return res?.data?.user;
    }

    return {error: 'Error occurred when fetching basic user information'};
  };

  const submitPassword = async (orgUrl: string, email: string, password: string) => {
    const res = await authHelper.fetchAuthInfoWithEmailAndPassword(orgUrl, email, password);
    console.log(res, 'res');
    if (!res || res.data.error) {
      switch (res.data.reason) {
        case 'account_locked':
          return {error: ACCOUNT_REMOVED_INPUT_ERROR};
        case 'invalid_password':
          return {error: 'Invalid password. Please try again'};

        default:
          return {error: GENERIC_PASSWORD_INPUT_ERROR};
      }
    }

    if (res && res.data) {
      const convertResponseToAuth = convertAuthResponseToAuthDTO(res.data);

      return {
        data: convertResponseToAuth,
      };
    }
  };

  const submitOTP = async (challengeId: string, otp: string, orgUrl: string) => {
    const res = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, otp);

    if (!res || res?.data?.errors) {
      if (res.data.errors?.[0].name === 'InvalidOTP') {
        return {error: 'Invalid OTP. Please try again'};
      } else {
        return {error: 'Error occurred when fetching OTP'};
      }
    }

    if (res && res.data?.response.status) {
      const otpGrantTypeResponse = await exchangeOTPTokenForPartialToken(challengeId);

      if (otpGrantTypeResponse?.data?.error) {
        return {
          error: 'Error occurred when fetching partial access token',
        };
      }

      if (otpGrantTypeResponse.data?.access_token) {
        const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

        if (!otpExchangeToken || otpExchangeToken?.data?.error) {
          switch (otpExchangeToken?.data?.reason) {
            case 'account_locked':
              return {error: ACCOUNT_REMOVED_INPUT_ERROR};
            case 'account_blocked':
              return {error: ACCOUNT_BLOCKED_INPUT_ERROR};
            default:
              return {error: 'Error occurred when trying to authenticate'};
          }
        }

        if (otpExchangeToken.data) {
          const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

          return {
            data: convertResponseToAuth,
          };
        }
      }
      if (otpGrantTypeResponse.error) {
        return {success: false, error: 'Error occurred when trying to authenticate'};
      }
    }

    return {
      success: false,
      error: 'Error occurred',
    };
  };

  const getAccessTokenforOTP = async (challengeId: string, orgUrl: string) => {
    const otpGrantTypeResponse = await exchangeOTPTokenForPartialToken(challengeId);

    if (otpGrantTypeResponse.data?.access_token) {
      const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

      if (otpExchangeToken?.data?.error) {
        return {
          error: otpExchangeToken.data.error_description,
        };
      }
      if (otpExchangeToken.data) {
        const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

        return {
          data: convertResponseToAuth,
        };
      }
    }
    if (otpGrantTypeResponse.error) {
      return {success: false, error: otpGrantTypeResponse.error};
    }
  };

  const exchangeOTPTokenForPartialToken = async (challengeId: string) => {
    const res = await AuthHelper.exchangeOTPTokenForPartialAccessToken(challengeId);
    return res;
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: TOO_MANY_CHALLENGES,
        };
      } else {
        return {
          error: 'ValidationRequestFail',
        };
      }
    }
  };

  const getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };

  const redirectUserToSignup = async (email: string) => {
    const challengeIdRes = await requestAddressOTP({address: email, type: 'email'});
    if (typeof challengeIdRes === 'string') {
      const challengeId: string = challengeIdRes;
      window.routerHistory.push(`/signup?email=${email?.replace('+', '%2b')}&challengeId=${challengeId}&step=2`);
    } else {
      if (challengeIdRes.error) {
        return {
          error: 'Too many challenges, please wait before starting another challenge',
        };
      } else {
        return {
          error: 'Failed to request validation code, please check your internet connection and try again',
        };
      }
    }
  };

  const getUnreadCountForLoggedInOrganizations = async () => {
    const ob = {};
    const loggedInAccounts = getAllLoggedInAccounts();

    if (loggedInAccounts.length > 0) {
      const promises = loggedInAccounts.map((acc) => fetchUnreadChatCountForUser(acc.accessToken || ''));

      console.log({promises});

      const res = await Promise.all(promises);

      console.log({res});

      res.forEach((response) => {
        if ('error' in response) {
          console.log(response.error);
          return;
        }

        const ress = response.data.me.unreadChatCount[0];

        ob[ress.organizationId] = ress.count;
      });
    }

    return ob;
  };

  return {
    fetchOrganizationByURL,
    submitEmailAddress,
    fetchOrganizationByName,
    submitPassword,
    submitOTP,
    getAccessTokenforOTP,
    handleOTPLogin,
    redirectUserToSignup,
    getUnreadCountForLoggedInOrganizations,
  };
};
