import React from 'react';
import {ChangePasswordRepository} from './ChangePasswordRepoistory';
import {ChangePasswordRequestVariables} from '../../../gql/v2/mutation/ChangePasswordMutation';

export const ChangePasswordViewModel = () => {
  const {changePassword} = ChangePasswordRepository();

  const requestChangePassword = async ({currentPassword, newPassword}: ChangePasswordRequestVariables) => {
    const res = await changePassword({currentPassword, newPassword});
    console.log(res, 'res');

    switch (res?.data?.selfMutation.changePassword.__typename) {
      case 'VulnerablePassword':
        return {error: 'Your password is too weak. Pick a stronger one to continue'};
      case 'PasswordRequirementsNotMet':
        return {error: 'The password requirements have not been met, please try again'};
      case 'InvalidPassword':
        return {error: 'Incorrect current password'};

      case 'FullOrganizationMember':
        return {success: true};

      default:
        return {error: 'Unknown error occurred'};
    }
  };

  return {requestChangePassword};
};
