import React, {useState} from 'react';
import {ChangePasswordViewModel} from './ChangePasswordViewModel';
import Helmet from 'react-helmet';
import {ChangePasswordContainer, HorizontalLogoWrapper, PageWrapper} from './changepassword.styled';
import {ResetPasswordEnterPasswordForm} from './ChangePasswordEnterPasswordForm';
import {CurrentResetPasswordStep, OrganizationAccountsCacheData} from '../../../types/sta';
import {ChangePasswordRequestVariables} from '../../../gql/v2/mutation/ChangePasswordMutation';
import {toast} from 'react-toastify';
import {LogoutViewModel} from '../../LoginPage/sta/view-models/LogoutViewModel';
import {SuccessToast} from '../../../components/CustomToasts';
import {NOTE_ADDED} from '../../../constants/hiddenNotes';
import {localStorageService} from '../../../services/localStorageService';
import {ORGANIZATION_ACCOUNTS_DATA} from '../../../constants/storageKeys';
import {getCurrentLoggedInAccount} from '../../../utils/sta/staUtils';
import {AuthContext} from '../../../auth';

const ChangePasswordCoordinator = ({logout}) => {
  const {requestChangePassword} = ChangePasswordViewModel();
  const {logoutOfAccount} = LogoutViewModel();
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
  let savedCacheData: OrganizationAccountsCacheData = localStorageService.getItem(ORGANIZATION_ACCOUNTS_DATA) ?? {
    savedOrganizations: [],
    selectedAccountUserId: '',
  };

  const handleSubmitChangePassword = async (values: ChangePasswordRequestVariables) => {
    console.log(values, 'values');
    setIsLoadingChangePassword(true);

    const res = await requestChangePassword({currentPassword: values.currentPassword, newPassword: values.newPassword});

    console.log(res, 'res');

    if (res?.success) {
      setIsLoadingChangePassword(false);
      toast.success(<SuccessToast title={'Your password has been changed! Please log in again'} />, {
        className: 'toast-message',
        autoClose: 7000,
      });

      const currentActive = getCurrentLoggedInAccount();
      currentActive && logoutOfAccount(currentActive);
      logout();
      window.routerHistory.push('/login');

      return {success: true};
    }

    if (res?.error) {
      setIsLoadingChangePassword(false);
      return {error: res.error};
    }
    setIsLoadingChangePassword(false);

    return {success: true};
  };

  return (
    <PageWrapper>
      <>
        <Helmet>
          <title>Change Password - Hypercare</title>
        </Helmet>
        <ChangePasswordContainer>
          <HorizontalLogoWrapper>
            <img src="/assets/horizontal-logo-red.svg" alt="hypercare logo with text" />
          </HorizontalLogoWrapper>
          <ResetPasswordEnterPasswordForm
            submitForm={handleSubmitChangePassword}
            isLoadingChangePassword={isLoadingChangePassword}
          />
        </ChangePasswordContainer>
      </>
    </PageWrapper>
  );
};

export default () => (
  <AuthContext.Consumer>{({logout}) => <ChangePasswordCoordinator logout={logout} />}</AuthContext.Consumer>
);
