import React from 'react';
import styled from '@emotion/styled';
import {UserAddress} from 'src/types';
import {TrashBinIcon} from 'src/svgs/TrashBinIcon';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {toast} from 'react-toastify';
import {PROFILE_ADDRESS_DELETE_LAST_ADDRESS_ERROR, SYNCED_INFORMATION} from 'src/constants/strings';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';
import SyncIcon from 'src/svgs/SyncIcon';

const DeleteRowIconHolder = styled.button`
  color: grey;
  position: relative;
  margin-left: 3px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  currentAddress: UserAddress;
  setCurrentAddresses?: (addresses: UserAddress[]) => void;
  addresses: UserAddress[];
}

const DeleteAddressActionIcon = ({currentAddress, addresses, setCurrentAddresses}: Props) => {
  const {handleUpdateDeleteAddress} = HypercareSelfProfileViewModel();

  const handleDeleteInfo = async () => {
    if (addresses.length <= 1) {
      toast.error(PROFILE_ADDRESS_DELETE_LAST_ADDRESS_ERROR, {
        toastId: 'failed1',
      });
      return;
    }

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileRemoveButtonPressed,
    });

    const newAddressArray = addresses.filter((address) => address.id !== currentAddress.id);

    if (!newAddressArray.some((address) => address.type === 'email')) {
      toast.error(
        <>
          <div>
            <span>
              <b>Email required:</b> You need at least one email in your profile.{' '}
            </span>
            <a
              href="https://support.hypercare.com/hc/en-us/articles/24953957201037-How-to-Add-an-Email-Address-and-Mobile-Number-to-Your-Profile"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: 'inherit', textDecoration: 'underline'}}
            >
              Learn More
            </a>
          </div>
        </>,
        {
          toastId: 'failed1',
        },
      );
      return;
    }

    setCurrentAddresses?.(newAddressArray);
    handleUpdateDeleteAddress(currentAddress);
  };

  return (
    <DeleteRowIconHolder type="button" disabled={currentAddress?.syncStatus} onClick={handleDeleteInfo}>
      {currentAddress.syncStatus ? (
        <StyledTooltipWhite arrow title={SYNCED_INFORMATION} placement="bottom-start" className="styledPopper">
          <div>
            <SyncIcon styles={{width: 16, height: 16}} />
          </div>
        </StyledTooltipWhite>
      ) : (
        <TrashBinIcon color={'#FF3E55'} />
      )}
    </DeleteRowIconHolder>
  );
};

export default DeleteAddressActionIcon;
