import {LoginContainer} from '../../../microfrontend/styled/login.styled';
import {HCCustomSpan, HCHeadingTwo} from '../../../components/HypercareComponents';
import React, {useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {ProgressContainer} from '../../LoginPage/styled/login.styled';
import {Icon, IconButton, InputAdornment, LinearProgress, Typography} from '@mui/material';
import {muiTheme} from '../../../styles/theme';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {ChangePasswordEnterPasswordFormProps, ChangePasswordEnterPasswordFormValues} from './changepassword.types';
import {ChangePasswordStyledButton, LoginContent, StyledForm} from './changepassword.styled';
import {StyledTextField} from '../../../styles/styled-components/StyledInputField';

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[!@#$%^&*]/, 'Password must contain at least one special character')
    .required('New password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm new password is required'),
});

// Password strength messages
const getPasswordStrengthMessages = (password: string, confirmPassword: string) => [
  {message: 'At least 8 characters', passed: !!password && password.length >= 8},
  {message: 'At least one special character (i.e. %, &)', passed: !!password && /[\W_]/.test(password)},
  {message: 'Passwords must match', passed: !!password && !!confirmPassword && password === confirmPassword},
];

// Component for password strength display
const PasswordStrengthDisplay = ({values}: {values: ChangePasswordEnterPasswordFormValues}) => (
  <>
    {getPasswordStrengthMessages(values.newPassword, values.confirmNewPassword).map((item, index) => (
      <Typography key={index} style={{color: item.passed ? '#00859a' : '#FF3E55'}}>
        <Icon style={{fontSize: 18, marginBottom: -4}}>{item.passed ? 'check' : 'close'}</Icon>
        &nbsp;{item.message}
      </Typography>
    ))}
  </>
);

export const ResetPasswordEnterPasswordForm = ({
  submitForm,
  isLoadingChangePassword,
}: ChangePasswordEnterPasswordFormProps) => {
  const [visibility, setVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const toggleVisibility = (field: keyof typeof visibility) => {
    setVisibility((prev) => ({...prev, [field]: !prev[field]}));
  };

  const renderPasswordField = (
    name: string,
    label: string,
    value: string,
    showPassword: boolean,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    error: string | undefined,
    touched: boolean | undefined,
    isSubmitting: boolean,
    toggleField: keyof typeof visibility,
  ) => (
    <StyledTextField
      variant="outlined"
      fullWidth
      name={name}
      label={label}
      value={value}
      type={showPassword ? 'text' : 'password'}
      autoComplete="off"
      placeholder="********"
      disabled={isSubmitting}
      onChange={onChange}
      onBlur={onBlur}
      helperText={touched && error}
      error={touched && Boolean(error)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              onClick={() => toggleVisibility(toggleField)}
              style={{marginRight: -8, color: muiTheme.colors.primary}}
            >
              {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Formik<ChangePasswordEnterPasswordFormValues>
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, {setSubmitting, setFieldError}) => {
        const res = await submitForm(values);
        if (res?.error) {
          if (res.error === 'Incorrect current password') {
            setFieldError('currentPassword', res.error);
          } else {
            setFieldError('newPassword', res.error);
            setFieldError('confirmNewPassword', res.error);
          }
        }
        setSubmitting(false);
      }}
    >
      {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
        <StyledForm onSubmit={handleSubmit}>
          <LoginContainer padding="32px" loading={isLoadingChangePassword ? 'true' : undefined}>
            {isLoadingChangePassword && (
              <ProgressContainer>
                <LinearProgress color="secondary" />
              </ProgressContainer>
            )}

            <LoginContent>
              <HCHeadingTwo color={muiTheme.colors.black}>Change Password</HCHeadingTwo>
              <div>
                <HCCustomSpan color={muiTheme.colors.greyishBrown}>Your password must contain:</HCCustomSpan>
                <PasswordStrengthDisplay values={values} />
              </div>

              {renderPasswordField(
                'currentPassword',
                'Current password',
                values.currentPassword,
                visibility.currentPassword,
                handleChange,
                handleBlur,
                errors.currentPassword,
                touched.currentPassword,
                isSubmitting,
                'currentPassword',
              )}
              {renderPasswordField(
                'newPassword',
                'New password',
                values.newPassword,
                visibility.newPassword,
                handleChange,
                handleBlur,
                errors.newPassword,
                touched.newPassword,
                isSubmitting,
                'newPassword',
              )}
              {renderPasswordField(
                'confirmNewPassword',
                'Retype new password',
                values.confirmNewPassword,
                visibility.confirmNewPassword,
                handleChange,
                handleBlur,
                errors.confirmNewPassword,
                touched.confirmNewPassword,
                isSubmitting,
                'confirmNewPassword',
              )}

              <ChangePasswordStyledButton type="submit" disabled={isSubmitting}>
                Finish
              </ChangePasswordStyledButton>
            </LoginContent>
          </LoginContainer>
        </StyledForm>
      )}
    </Formik>
  );
};
