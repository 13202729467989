import styled from '@emotion/styled';
import {PrimaryButton} from '../../../styles/styled-components/StyledMaterialComponents';

export const HorizontalLogoWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
`;

export const PageWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
`;

export const ChangePasswordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  @media only screen and (max-width: 600px) {
    padding: 8px;
  }
`;

export const ChangePasswordStyledButton = styled(PrimaryButton)`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ChangePasswordStyledDescription = styled.div`
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OptionContainer = styled.ul`
  height: auto;
  width: 100%;
  margin: 0;
`;
