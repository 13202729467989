import * as React from 'react';
import styled from '@emotion/styled';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import ProfilePic from 'src/components/ProfilePic';
import {Query, QueryResult} from 'react-apollo';
import SelfProfileQuery from 'src/gql/query/GetSelfProfile';
import {
  StyledLink,
  OptionsBox,
  Option,
  DisabledOption,
  OptionContainer,
  OptionMain,
  SubOptionContainer,
  SubOption,
  DisabledOptionOpacity,
} from 'src/styles/styled-components/PopoverMenuComponents';
import {Divider} from '@mui/material';
import ChangeStatusOptions from 'src/pages/MessengerPage/messenger/change-status/ChangeStatusOptions';
import {
  ARCHIVED,
  CHANGEPASSWORD,
  JOINORGANIZATION,
  MESSENGER,
  LOCATING,
  CONTACTS,
  TEMPLATEREPORT,
  DEBUG,
} from 'src/constants/routerPathName';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {AuthRegion, User, WorkStatus} from 'src/types';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';
import {
  CHANGE_PASSWORD,
  INVITE_COLLEAGUES,
  JOIN_ORGANIZATION,
  SSO_ENABLED_DESC,
  TEMPLATE_MESSAGE_REPORT,
  VIEW_ARCHIVED_MESSAGES,
  VIEW_PROFILE,
  DEBUG_CONSTANT,
  REPORT,
} from 'src/constants/strings';
import getParsedAuthInfo, {getParsedAuthRegion} from '../../../../utils/localStorageHandler';
import {AuthRegionFlag} from '../../../../components/shared/AuthRegionFlag';
import {AuthRegionMap, UNITED_STATES, UNITED_STATES_ABBREVIATION} from '../../../../constants/strings';
import {checkIfUserIsSignedIn} from '../../../../utils/userHelper/userUtils';
import {localStorageService} from '../../../../services/localStorageService';
import {ORGANIZATION_ACCOUNTS_DATA} from '../../../../constants/storageKeys';
import {OrganizationAccountsCacheData} from '../../../../types/sta';
import {LogoutViewModel} from '../../../LoginPage/sta/view-models/LogoutViewModel';
import {toast} from 'react-toastify';
import {muiTheme} from 'src/styles/theme';

const StyledDivider = styled(Divider)`
  background-color: white !important;
  margin: 5px 0 !important;
`;

const MenuWrapper = styled.div`
  margin-right: 1em;
`;

const StyledOptionsBox = styled(OptionsBox)`
  top: 5px !important;
  z-index: 9 !important;
`;

const NameSpan = styled.span`
  text-transform: none;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: Nunito;
  max-width: 300px;
  color: ${muiTheme.colors.primaryText};
`;

interface IUserBlockOptions {
  selfId: string;
  clickedOutside: () => void;
  openChangeStatusModal: (workStatus?: WorkStatus) => void;
  tryMessageSupportUser: () => Promise<void>;
  logout: () => void;
  authRegion: AuthRegion;
}

const UserBlockOptions = ({
  openChangeStatusModal,
  clickedOutside,
  selfId,
  authRegion,
  tryMessageSupportUser,
  logout,
}: IUserBlockOptions) => {
  const [showVersionModal, setShowVersionModal] = React.useState(false);
  const [showStaLogoutModal, setShowStaLogoutModal] = React.useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = React.useState(false);
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const reportButtonFlag = IsFeatureFlagEnabled(FeatureFlagResult.templatedMessagesReportButton);

  const STAFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const {useGetOrganizationAllowInviteStatus, useGetProfileInformation} = HypercareSelfProfileViewModel();

  const {data: organizationAllowInviteStatus} = useGetOrganizationAllowInviteStatus();
  const {data: userInformationData} = useGetProfileInformation();

  const {logoutOfAccount, removeAccount} = LogoutViewModel(logout);

  const currentAuthRegion = getParsedAuthRegion();

  const handleMenuMixpanelEvents = (event) => {
    AnalyticsManager.applyAnalytics({
      eventName: event,
    });
  };

  const handleOpenStaLogoutModal = () => {
    setShowStaLogoutModal(true);
  };

  const handleRemoveAccount = (existingOrg) => {
    const removeAccountResponse = removeAccount(existingOrg);

    if (removeAccountResponse?.success) {
      toast.info(
        `${existingOrg?.user.firstname} ${existingOrg?.user.lastname} (${existingOrg?.organization?.name}) has been removed from browser.`,
      );
      logout();
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setShowStaLogoutModal(false);
  };

  const handleLogoutOfAccount = (existingOrg) => {
    const logoutResponse = logoutOfAccount(existingOrg);

    if (logoutResponse?.success) {
      toast.info(
        `Logged out of account ${existingOrg?.user.firstname} ${existingOrg?.user.lastname} (${existingOrg?.organization?.name})`,
      );
      logout();
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setShowStaLogoutModal(false);
  };

  const renderLogoutOfAccountModal = () => {
    const authInfo = getParsedAuthInfo();
    const cacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    const existingOrgIndex = cacheData?.savedOrganizations.findIndex((org) => org.user.id === authInfo?.user.id) || 0;

    if (cacheData && existingOrgIndex !== -1) {
      const existingOrg = cacheData.savedOrganizations[existingOrgIndex];

      const isUserSignedIn = checkIfUserIsSignedIn(existingOrg);

      const userFullName = `${existingOrg?.user.firstname} ${existingOrg?.user.lastname || ''}`;
      const organizationName = existingOrg?.organization.name;

      const title = isUserSignedIn
        ? `Logout out of ${userFullName} (${organizationName})`
        : `Remove account ${userFullName} (${organizationName}) from browser?`;

      const subtitle = isUserSignedIn
        ? `We recommend also removing your account from device upon logging out if you’re using a shared device.`
        : 'You’ll need to enter your credentials again the next time you log into Hypercare on this device.';

      const alertModalButtons = [
        {
          type: 'primary',
          buttonLabel: 'Logout & Remove',
          onClickHandler: () => handleRemoveAccount(existingOrg),
          id: 'cancel-btn',
        },
        {
          type: 'primary',
          buttonLabel: 'Logout',
          onClickHandler: () => handleLogoutOfAccount(existingOrg),
          id: 'remove-btn',
        },
      ];

      return (
        <AlertModal
          width="sm"
          title={title}
          titleFontSize="21px"
          subtitle={subtitle}
          closeAlertModal={() => setShowStaLogoutModal(false)}
          isAlertModalVisible={showStaLogoutModal}
          alertModalButtons={alertModalButtons}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <DisabledOption>
        Current region:
        <AuthRegionFlag currentRegion={currentAuthRegion} size={'small'} />
        {currentAuthRegion === UNITED_STATES_ABBREVIATION ? UNITED_STATES : AuthRegionMap[currentAuthRegion]}
      </DisabledOption>

      <StyledDivider />

      <ChangeStatusOptions openChangeStatusModal={openChangeStatusModal} clickedOutside={clickedOutside} />

      <Option onClick={clickedOutside}>
        <StyledLink to={`/${window.location.href.includes(LOCATING) ? LOCATING : MESSENGER}/user/${selfId}`}>
          {VIEW_PROFILE}
        </StyledLink>
      </Option>

      <StyledDivider />

      <Option onClick={clickedOutside}>
        <StyledLink to={`/${ARCHIVED}/home`}>{VIEW_ARCHIVED_MESSAGES}</StyledLink>
      </Option>

      {reportButtonFlag && (
        <Option>
          <OptionContainer>
            <OptionMain>
              {REPORT}{' '}
              <span>
                <i className="material-icons" style={{fontSize: '12px'}}>
                  keyboard_arrow_right
                </i>
              </span>
              <SubOptionContainer>
                <SubOption>
                  <StyledLink to={`/${TEMPLATEREPORT}`}>{TEMPLATE_MESSAGE_REPORT}</StyledLink>
                </SubOption>
              </SubOptionContainer>
            </OptionMain>
          </OptionContainer>
        </Option>
      )}

      {organizationAllowInviteStatus && (
        <Option onClick={clickedOutside}>
          <StyledLink to={`/${CONTACTS}?open=true`}>{INVITE_COLLEAGUES}</StyledLink>
        </Option>
      )}

      <Option>
        <StyledLink to={`/${JOINORGANIZATION}`}>{JOIN_ORGANIZATION}</StyledLink>
      </Option>

      {!userInformationData?.isSSOEnabled && (
        <Option>
          <StyledLink to={`/${CHANGEPASSWORD}`}>{CHANGE_PASSWORD}</StyledLink>
        </Option>
      )}

      {ldapDirectorySyncFlag && userInformationData?.isSSOEnabled && (
        <StyledTooltipWhite
          title={SSO_ENABLED_DESC}
          className="styledPopper"
          placement="left"
          open={isToolTipOpen}
          onMouseEnter={() => setIsToolTipOpen(true)}
          onMouseLeave={() => setIsToolTipOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
        >
          <span>
            <DisabledOptionOpacity>
              <StyledLink to={`/${CHANGEPASSWORD}`}>{CHANGE_PASSWORD}</StyledLink>
            </DisabledOptionOpacity>
          </span>
        </StyledTooltipWhite>
      )}

      <Option>
        <StyledLink to={`/${DEBUG}`}>{DEBUG_CONSTANT}</StyledLink>
      </Option>

      <StyledDivider />

      <Option
        onClick={() => {
          window.open('https://support.hypercare.com', '_blank', 'noopener,noreferrer');
          handleMenuMixpanelEvents(EVENTS.FAQButtonPressed);
        }}
      >
        FAQ
      </Option>

      <Option
        onClick={() => {
          tryMessageSupportUser();
          handleMenuMixpanelEvents(EVENTS.contactSupportButtonPressed);
        }}
      >
        Contact Support
      </Option>
      <Option
        onClick={() => {
          setShowVersionModal(true);
          handleMenuMixpanelEvents(EVENTS.aboutButtonPressed);
        }}
      >
        About
      </Option>

      <StyledDivider />

      <Option onClick={!STAFlag ? handleOpenStaLogoutModal : logout} style={{marginBottom: '4px'}}>
        Logout
      </Option>

      {showVersionModal && (
        <AlertModal
          title={`About us`}
          subtitle={`Hypercare version is: ${process.env.REACT_APP_VERSION}`}
          titleFontSize={'21px'}
          closeAlertModal={() => setShowVersionModal(false)}
          isAlertModalVisible={showVersionModal}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowVersionModal(false),
            },
          ]}
        />
      )}
      {showStaLogoutModal && renderLogoutOfAccountModal()}
    </React.Fragment>
  );
};

interface IRightSectionUserBlock {
  user: User;
  isMenuOpen: boolean;
  clickedOutside: () => void;
  onClickMenu: () => void;
  openChangeStatusModal: (workStatus?: WorkStatus) => void;
  tryMessageSupportUser: () => Promise<void>;
  logout: () => void;
  authRegion: AuthRegion;
}

const RightSectionUserBlock = ({
  user,
  isMenuOpen,
  clickedOutside,
  onClickMenu,
  openChangeStatusModal,
  tryMessageSupportUser,
  logout,
  authRegion,
}: IRightSectionUserBlock) => {
  const {firstname, lastname, id} = user as User;

  return (
    <ClickAwayListener onClickAway={clickedOutside}>
      <MenuWrapper>
        <Manager>
          <Reference>
            {({ref}) => (
              <Button ref={ref} variant="outlined" disableTouchRipple onClick={onClickMenu}>
                <Query query={SelfProfileQuery}>
                  {({loading, error, data}: QueryResult) => {
                    if (loading || error) return <div />;
                    if (data && data.me) {
                      const {me} = data;
                      if (me && me.workStatus) user.workStatus = me.workStatus;
                      if (me && me.profilePic) user.profilePic = me.profilePic;
                    }
                    return <ProfilePic size={30} users={[user]} />;
                  }}
                </Query>
                <NameSpan>{`${firstname || ''} ${lastname || ''}`}</NameSpan>
              </Button>
            )}
          </Reference>

          <Popper placement="bottom">
            {({ref, style, placement}) =>
              isMenuOpen && (
                <StyledOptionsBox ref={ref} style={style} data-placement={placement}>
                  <UserBlockOptions
                    selfId={id}
                    authRegion={authRegion}
                    openChangeStatusModal={openChangeStatusModal}
                    clickedOutside={clickedOutside}
                    tryMessageSupportUser={tryMessageSupportUser}
                    logout={logout}
                  />
                </StyledOptionsBox>
              )
            }
          </Popper>
        </Manager>
      </MenuWrapper>
    </ClickAwayListener>
  );
};

export default RightSectionUserBlock;
