import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {AuthContext} from 'src/auth/AuthProvider';
import LoadingDiv from 'src/components/LoadingDiv';
import {AuthSSOVendors, LoginViaSSO, StaSSOLogin} from 'src/types';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import sleep from '../../utils/sleep';
import {ACCOUNT_DISCOVERY_FLOW_STEP, EMAIL, ORG_URL, SSO_VENDOR} from '../../constants/storageKeys';
import {IsFeatureFlagEnabled} from '../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../utils/FeatureFlags';
import {localStorageService} from '../../services/localStorageService';
import {LoginErrorSSOModal} from 'src/auth/LoginErrorSSOModal';

import {AccountDiscoveryViewModel} from '../LoginPage/sta/view-models/AccountDiscoveryViewModel';
import {useContext} from 'react';
import HypercareErrorModalContext from '../../contexts/ErrorModalContext';
import {USER_BLOCKED, USER_BLOCKED_DESCRIPTION, USER_BLOCKED_TITLE} from '../../constants/login';
import {OrgViews, WebViewCallBacks} from '../../types/sta';
import {callNative} from '../../nativeBridge';
import {
  ACCOUNT_BLOCKED_ERROR_CODE,
  INVALID_SCOPE_ERROR_CODE,
  INVALID_SCOPE_ERROR_DESCRIPTION,
  INVALID_SCOPE_ERROR_TITLE,
  SERVER_ERROR,
  SERVER_ERROR_DESCRIPTION,
  TOO_MANY_CHALLENGES_MSG,
} from '../../constants/networkError';

interface Props {
  isLoggedIn: boolean;
  loginViaSSO: LoginViaSSO;
  auth0props: Auth0ContextInterface;
  staSSOLogin: StaSSOLogin;
  logout: () => void;
}

const SSORedirectLoadingPage = ({
  isLoggedIn,
  loginViaSSO,
  auth0props,
  staSSOLogin,
  logout: authProviderLogout,
}: Props) => {
  const {isAuthenticated, getAccessTokenSilently, logout} = auth0props;
  const STAFlag = !IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const {handleAccountDiscoveryAfterSSO} = AccountDiscoveryViewModel(authProviderLogout);

  const {setErrorModalOpenStatus, setErrorModalDetails} = useContext(HypercareErrorModalContext);

  React.useEffect(() => {
    const getSSOVendor = (localStorage.getItem(SSO_VENDOR) || '') as AuthSSOVendors;
    const flowStep = localStorage.getItem('flowStep') || '';
    const orgUrl = localStorage.getItem(ORG_URL) || '';
    const email = localStorage.getItem(EMAIL) || '';

    const getAccessToken = async () => {
      if (isAuthenticated && getSSOVendor === AuthSSOVendors.AUTH0) {
        getAccessTokenSilently()
          .then(async (token) => {
            STAFlag ? await staSSOLogin(token, getSSOVendor, orgUrl, email) : await loginViaSSO(token, getSSOVendor);
          })
          .catch(async (err) => {
            console.error(err);

            await sleep(3000);
            logout({federated: true});
          });
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code') || '';

        switch (flowStep) {
          case ACCOUNT_DISCOVERY_FLOW_STEP:
            const res = await handleAccountDiscoveryAfterSSO(code, getSSOVendor, email);
            if (res && 'screen' in res) {
              window.routerHistory.push(`/login?view=${OrgViews.PASSWORD}`);
            }
            if (res && 'error' in res && res.error) {
              switch (res.error) {
                case INVALID_SCOPE_ERROR_CODE:
                  callNative(WebViewCallBacks.REPORT_ERROR, {
                    error: INVALID_SCOPE_ERROR_TITLE,
                  });
                  setErrorModalDetails({
                    title: INVALID_SCOPE_ERROR_TITLE,
                    description: INVALID_SCOPE_ERROR_DESCRIPTION,
                  });
                  setErrorModalOpenStatus(true);
                  window.routerHistory.push('/login');
                  return;

                case ACCOUNT_BLOCKED_ERROR_CODE:
                  callNative(WebViewCallBacks.REPORT_ERROR, {
                    error: USER_BLOCKED_TITLE,
                  });
                  setErrorModalDetails({
                    title: USER_BLOCKED_TITLE,
                    description: USER_BLOCKED_DESCRIPTION(),
                  });
                  setErrorModalOpenStatus(true);
                  window.routerHistory.push('/login');
                  return;
                default:
                  callNative(WebViewCallBacks.REPORT_ERROR, {
                    error: SERVER_ERROR,
                  });
                  setErrorModalDetails({
                    title: SERVER_ERROR,
                    description: SERVER_ERROR_DESCRIPTION,
                  });
                  setErrorModalOpenStatus(true);
                  window.routerHistory.push('/login');
                  return;
              }
            }

            console.log('2');
            return res;
          case 'findMyOrg':
            const result = await staSSOLogin(code, getSSOVendor, orgUrl, email);

            if (result && result.error) {
              switch (result.error) {
                case USER_BLOCKED:
                  callNative(WebViewCallBacks.REPORT_ERROR, {
                    error: USER_BLOCKED_TITLE,
                  });
                  setErrorModalDetails({
                    title: USER_BLOCKED_TITLE,
                    description: USER_BLOCKED_DESCRIPTION(),
                  });
                  setErrorModalOpenStatus(true);
                  window.routerHistory.push('/login');
                  return;

                case ACCOUNT_BLOCKED_ERROR_CODE:
                  callNative(WebViewCallBacks.REPORT_ERROR, {
                    error: USER_BLOCKED_TITLE,
                  });
                  setErrorModalDetails({
                    title: USER_BLOCKED_TITLE,
                    description: USER_BLOCKED_DESCRIPTION(),
                  });
                  setErrorModalOpenStatus(true);
                  window.routerHistory.push('/login');
                  return;
                default:
                  callNative(WebViewCallBacks.REPORT_ERROR, {
                    error: SERVER_ERROR,
                  });
                  setErrorModalDetails({
                    title: SERVER_ERROR,
                    description: SERVER_ERROR_DESCRIPTION,
                  });
                  setErrorModalOpenStatus(true);
                  window.routerHistory.push('/login');
                  return;
              }
            }

            return result;
          default:
            return staSSOLogin(code, getSSOVendor, orgUrl, email);
        }
      }
    };

    getAccessToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // if (isLoggedIn) return <Redirect to="/messenger" />;
  return <LoadingDiv />;
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn, loginViaSSO, auth0props, staSSOLogin, logout}) => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlErrorDescription = urlParams.get('error_description');
      if (urlErrorDescription) {
        return <LoginErrorSSOModal description={urlErrorDescription} />;
      }
      return (
        <SSORedirectLoadingPage
          staSSOLogin={staSSOLogin}
          auth0props={auth0props}
          isLoggedIn={isLoggedIn}
          loginViaSSO={loginViaSSO}
          logout={logout}
        />
      );
    }}
  </AuthContext.Consumer>
);
