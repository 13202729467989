import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Chevron from 'src/svgs/Chevron';
import styled from '@emotion/styled';
import {SmallLoader} from 'src/components/LoadingDiv';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import {CONTACT_LIST_ERROR, NO_USERS} from 'src/constants/user';
import InvitationListV2 from '../InvitedUserList/InvitationListV2';
import {CircularProgress} from '@mui/material';
import InvitationListSTA from '../InvitedUserList/InvitationListSTA';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

const PREFIX = 'InvitationUserListWrapperV2';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  expandWrapper: `${PREFIX}-expandWrapper`,
  summaryHeaderWrapper: `${PREFIX}-summaryHeaderWrapper`,
};

const UserListWrapper = styled.div`
  display: contents;
  max-height: 100%;
`;

const StyledUserListWrapper = styled(UserListWrapper)(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.expandWrapper}`]: {
    boxShadow: 'none',
    margin: '0 !important',
  },

  [`& .${classes.summaryHeaderWrapper}`]: {
    position: 'sticky',
    top: 0,
    padding: '4px 0.5em',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

const InviteSummary = styled.span`
  font-weight: bold;
  font-family: Nunito;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.colors.greyishBrown};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InvitationUserListWrapperSTA = () => {
  const userViewModel = UserViewModel();

  const paginatedPendingInvitesSTADataQuery = userViewModel.fetchPaginatedInvitesSTA({
    pageInfo: {
      pageSize: 30,
      cursor: null,
    },
  });
  const pendingInvitesResultListSTA = paginatedPendingInvitesSTADataQuery?.data?.selfQuery?.invites.invites;
  const pendingUserInvitesListCountSTA =
    paginatedPendingInvitesSTADataQuery?.data?.selfQuery?.invites.pageInfo?.totalResults;
  if (paginatedPendingInvitesSTADataQuery?.loading)
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  if (!pendingInvitesResultListSTA) return <p>{NO_USERS}</p>;
  if (paginatedPendingInvitesSTADataQuery?.error) return <p>{CONTACT_LIST_ERROR}</p>;

  return (
    <StyledUserListWrapper className={classes.root}>
      <Accordion defaultExpanded={false} className={classes.expandWrapper}>
        <AccordionSummary
          className={classes.summaryHeaderWrapper}
          expandIcon={<Chevron />}
          aria-controls="invite-summary"
        >
          <Typography className={classes.heading}>
            <InviteSummary>Pending Invites ({pendingUserInvitesListCountSTA})</InviteSummary>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: 0}}>
          <InvitationListSTA
            invitedUserList={pendingInvitesResultListSTA}
            paginatedPendingInvitesData={paginatedPendingInvitesSTADataQuery?.data}
            fetchMorePaginatedInvitesData={paginatedPendingInvitesSTADataQuery?.fetchMore}
          />
        </AccordionDetails>
      </Accordion>
    </StyledUserListWrapper>
  );
};

export default InvitationUserListWrapperSTA;
