import gql from 'graphql-tag';
import {InvitedUser} from 'src/types';

export type FetchPaginatedSentInvitesQueryResponse = {
  selfQuery: {
    invites: {
      pageInfo: {
        hasNextPage: boolean;
        startCursor: string;
        endCursor: string;
        totalResults: number;
        nextCursor: string;
        pageSize: number;
        resultsCount: number;
      };
      invites: InvitedUser[];
    };
  };
};

export const FETCH_PAGINATED_SENT_INVITES = gql`
  query PaginatedSentInvites($filters: InviteFilters, $pageInfo: CursorPageInput) {
    selfQuery {
      invites(filters: $filters, pageInfo: $pageInfo) {
        __typename
        ... on PaginatedPendingInvitesResponse {
          invites {
            ...InviteFragment
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
            totalResults
            nextCursor
            pageSize
            resultsCount
          }
        }
      }
    }
  }

  fragment InviteFragment on UserInvite {
    id
    status
    user {
      ...OrganizationMemberFragment
    }
  }

  fragment OrganizationMemberFragment on OrganizationMember {
    id
    firstname: firstName
    lastname: lastName
    role
    memberStatus
  }
`;
