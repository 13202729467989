import {
  ATTACHMENT,
  GeneralMessagePriority,
  IMAGE,
  StatMessagePriority,
  TEMPLATE,
  UrgentMessagePriority,
} from 'src/constants/messageTypes';
import {
  ARCHIVED,
  CHANGEPASSWORD,
  CONTACTS,
  JOINORGANIZATION,
  LOCATING,
  MESSENGER,
  SCHEDULING,
} from 'src/constants/routerPathName';
import {updateScopeToken} from 'src/utils/localStorageHandler';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import getCachedOrganizationById from 'src/utils/organizationHelper/getCachedOrganizationById';
import store from 'src/redux';
import {actions} from 'src/redux/actions/organization';

export const FirebaseNotificationBuilder = (payload, accountId, isServiceWorker: boolean) => {
  const {chat, message, organization} = payload;
  const {sender} = message;

  const messageSenderOrganizationId = organization.id;
  const chatId = chat.id;

  const windowClientHref = window.location.href;

  function playStatPriorityNotificationSound() {
    const audio: HTMLAudioElement = document.getElementById('priority-notification-player') as HTMLAudioElement;
    tryPlayAudio(audio);
  }

  function playUrgentPriorityNotificationSound() {
    const audio: HTMLAudioElement = document.getElementById('urgent-notification-player') as HTMLAudioElement;
    tryPlayAudio(audio);
  }

  function playRegularNotificationSound() {
    const audio: HTMLAudioElement = document.getElementById('regular-notification-player') as HTMLAudioElement;
    tryPlayAudio(audio);
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/notification/silent
  // note that firefox does not support silent
  function tryPlayAudio(audioElement: HTMLAudioElement) {
    audioElement.muted = false;
    const audioPromise = audioElement.play();
    if (audioPromise !== undefined) {
      audioPromise.catch(function (error) {
        // TODO: Show a UI element to let the user turn on sound setting
        console.error(error);
      });
    }
  }

  const dispatchServiceWorkerNotification = (notificationTitle, notificationPayload) => {
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(notificationTitle, notificationPayload);
    });
  };
  const dispatchForegroundNotification = (notificationTitle, notificationPayload, chatId, organizationId) => {
    const notification: Notification = new Notification(notificationTitle, notificationPayload);
    notification.addEventListener('click', (e) => {
      const currentOrganizationID = getCurrentOrganizationId();
      if (currentOrganizationID !== organizationId) {
        const notificationOrganization = getCachedOrganizationById(organizationId);
        store.dispatch(actions.setCurrentOrganization(notificationOrganization));
        updateScopeToken({organizationId});
        window.focus();
        window.routerHistory.push(`/messenger/${chatId}`);
        window.location.reload();
      } else {
        window.focus();
        window.routerHistory.push(`/messenger/${chatId}`);
      }
      notification.close();
    });
  };

  function getNotificationMessageBody(message, sender, chat) {
    const {firstname, lastname} = sender;
    const {title} = chat;

    let notificationBodyPrefix: string = '';
    let notificationBodySuffix: string = '';

    switch (chat?.type) {
      case 'single':
        notificationBodyPrefix = `${firstname} ${lastname}`;
        break;
      case 'group':
        if (!title) {
          notificationBodyPrefix = `${firstname} ${lastname} to the group`;
          break;
        } else {
          notificationBodyPrefix = `${firstname} ${lastname} to the ${title}`;
          break;
        }
      default:
        break;
    }

    switch (message.type) {
      case IMAGE:
        notificationBodySuffix = `Sent an image.`;
        break;
      case TEMPLATE:
        notificationBodySuffix = 'Sent a template message.';
        break;
      case ATTACHMENT:
        notificationBodySuffix = `Sent an attachment.`;
        break;
      default:
        notificationBodySuffix = `${message.message}`;
    }

    const notificationBody = `${notificationBodyPrefix}: ${notificationBodySuffix}`;

    return notificationBody;
  }

  if (message.priorityType === StatMessagePriority) playStatPriorityNotificationSound();
  else if (message.priorityType === UrgentMessagePriority) playUrgentPriorityNotificationSound();

  if (
    !window.document.hasFocus() ||
    !windowClientHref.includes(chatId) ||
    windowClientHref.includes(CHANGEPASSWORD) ||
    windowClientHref.includes(JOINORGANIZATION) ||
    windowClientHref.includes(LOCATING) ||
    windowClientHref.includes(ARCHIVED) ||
    windowClientHref.includes(CONTACTS) ||
    windowClientHref.includes(SCHEDULING) ||
    windowClientHref.includes(`${MESSENGER}/new`)
  ) {
    if (message.priorityType === GeneralMessagePriority) playRegularNotificationSound();
    const notificationBody = getNotificationMessageBody(message, sender, chat);
    const notificationOrganization = getCachedOrganizationById(messageSenderOrganizationId);

    const notificationTitle = [StatMessagePriority, UrgentMessagePriority].includes(message.priorityType)
      ? `${message.priorityType === UrgentMessagePriority ? 'Urgent' : 'Stat'} message from ${
          notificationOrganization ? notificationOrganization.name : 'one of your organization'
        }`
      : notificationOrganization
      ? notificationOrganization.name
      : 'You have new message waiting for you on Hypercare';

    const notificationPayload = {
      icon: '/favicon-normal.ico',
      body: notificationBody,
      silent: true,
      tag: message.id,
      data: {
        chatId,
        messageSenderOrganizationId,
        accountId,
      },
    };

    if (!isServiceWorker) {
      dispatchServiceWorkerNotification(notificationTitle, notificationPayload);
    } else {
      dispatchForegroundNotification(notificationTitle, notificationPayload, chatId, messageSenderOrganizationId);
    }
  }
};
