import gql from 'graphql-tag';

export interface FetchUnreadChatCountResponse {
  me: {
    id: string;
    unreadChatCount: {
      count: number;
      organizationId: number;
    }[];
  };
}

export default gql`
  query FetchUnreadCount {
    me {
      id
      unreadChatCount {
        count
        organizationId
      }
    }
  }
`;
