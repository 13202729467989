import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  BACK_TO_LOGIN_LINK,
  RESET_PASSWORD_INVALID_LINK_DESCRIPTION,
  RESET_PASSWORD_INVALID_LINK_TITLE,
  RESET_PASSWORD_LINK_BUTTON_TEXT,
  RESET_PASSWORD_LINK_EXPIRED_DESCRIPTION,
  RESET_PASSWORD_LINK_EXPIRED_TITLE,
  RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_DESCRIPTION,
  RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_MOBILE_DESCRIPTION,
  RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_TITLE,
  RESET_PASSWORD_SUCCESS_DESCRIPTION,
  RESET_PASSWORD_SUCCESS_DESCRIPTION_2,
  RESET_PASSWORD_SUCCESS_TITLE,
  RESET_PASSWORD_TITLE,
} from '../../constants/strings';
import {HCCustomSpan, HCHeadingTwo, HCLabelOne, HCTextContextThree} from '../../components/HypercareComponents';
import {
  CircleFilledWithGreenCheck,
  CircleWithCrossInMiddle,
  HorizontalLogoWrapper,
  PageWrapper,
  ResetPwdContainer,
} from './styles/resetpassword.styled';
import {ResetPasswordViewModel} from './ResetPasswordViewModel';
import {CurrentResetPasswordStep} from '../../types/sta';
import {CustomStatusMessage} from './components/ResetPasswordCustomStatusMessages';
import {toast} from 'react-toastify';
import {ResetPasswordEnterPasswordForm} from './components/ResetPasswordEnterPasswordForm';
import {ResetPasswordMaskedEmailForm} from './components/ResetPasswordMaskedEmailForm';
import {muiTheme} from '../../styles/theme';
import CircularProgress from '@mui/material/CircularProgress';
import {MaskedAddresses} from '../../gql/v2/query/FetchMaskedAddressesForUser';
import ErrorModalContext from '../../contexts/ErrorModalContext';
import {TOO_MANY_CHALLENGES} from '../../constants/networkError';
import {CONTACT_ADMIN_FOR_HELP} from '../../constants/login';

export const ResetPasswordCoordinator = () => {
  const {search} = useLocation();
  let urlSearchParams = new URLSearchParams(search);
  const email = urlSearchParams.get('email');
  const organizationUrl = urlSearchParams.get('orgUrl');
  const organizationName = urlSearchParams.get('orgName');
  const token = urlSearchParams.get('token') || '';
  const challengeId = urlSearchParams.get('challengeId') || '';

  const [maskedAddresses, setMaskedAddresses] = useState<{id: string; address: string; checked: boolean}[]>([]);
  const [error, setError] = useState('');
  const [currentStep, setCurrentStep] = useState<CurrentResetPasswordStep>(CurrentResetPasswordStep.STEP_1);
  const [isLoadingMaskedAddresses, setIsLoadingMaskedAddresses] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [isLoadingSubmitPassword, setIsLoadingSubmitPassword] = useState(false);
  const [selectedMaskedAddress, setSelectedMaskedAddress] = useState('');
  const {setErrorModalDetails, setErrorModalOpenStatus} = useContext(ErrorModalContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const {getMaskedAddresses, initiateResetPasswordFlow, validateOTPToken, resetPasswordRequest} =
    ResetPasswordViewModel();

  useEffect(() => {
    const getData = async () => {
      setIsLoadingMaskedAddresses(true);
      if (!email && !organizationUrl) {
        setError('No query param available');
        return;
      }

      const res = await getMaskedAddresses({email, organizationUrl});

      if (!res) {
        setError('Unknown error when fetching masked addresses');
      }

      if (res && 'error' in res) {
        setErrorModalDetails({
          title: 'No accounts found',
          description: `No accounts found for ${email} in ${organizationName}, please contact admin or Hypercare support for assistance`,
        });
        setErrorModalOpenStatus(true);
        window.routerHistory.push('/login');
      }

      if (res.data) {
        if (res.data.length === 1) {
          setValue(res.data[0].id);
          await handleInitiateResetPasswordFlow(res.data[0].id, res.data[0].address);
        } else {
          const newArray = res.data.map((address) => ({checked: false, ...address}));
          setMaskedAddresses(newArray);
        }
      }

      setIsLoadingMaskedAddresses(false);
    };

    getData();
  }, []);

  useEffect(() => {
    const checkIfPasswordLinkIsValid = async () => {
      if (!challengeId && !token) {
        setError('No query param available');
        return;
      }
      setLoading(true);

      const res = await validateOTPToken(challengeId, token);
      setLoading(false);

      if (res?.error) {
        switch (res.error) {
          case 'ChallengeTimeExpired':
            return setCurrentStep(CurrentResetPasswordStep.STEP_4);
          case 'NoChallengeEntry':
            return setCurrentStep(CurrentResetPasswordStep.STEP_5);
          default:
            return setCurrentStep(CurrentResetPasswordStep.STEP_5);
        }
      }
      if (res && res.success) {
        setCurrentStep(CurrentResetPasswordStep.STEP_3);
      }
    };

    checkIfPasswordLinkIsValid();
  }, []);

  const handleInitiateResetPasswordFlow = async (value: string, currentMaskedAddress?: string) => {
    setIsLoadingSendEmail(true);

    const maskedAddress = maskedAddresses.find((address) => address.id === value);
    setSelectedMaskedAddress(currentMaskedAddress || maskedAddress?.address || '');
    const res = await initiateResetPasswordFlow(value);

    if (res.error) {
      let errorTitle = '';
      let errorDescription = '';

      if (res.error === TOO_MANY_CHALLENGES) {
        errorTitle = 'Too many verification codes sent';
        errorDescription = 'Please try again later.';
      } else {
        errorTitle = 'Unknown error occurred when reset password';
        errorDescription = CONTACT_ADMIN_FOR_HELP;
      }

      setErrorModalDetails({
        title: errorTitle,
        description: errorDescription,
      });
      setErrorModalOpenStatus(true);

      window.routerHistory.push('/login');
    }

    if (res.success) {
      setCurrentStep(CurrentResetPasswordStep.STEP_2);
    }
    setIsLoadingSendEmail(false);
  };

  const handleResend = async () => {
    const res = await initiateResetPasswordFlow(value);

    if (res.error) {
      toast.error(res.error);
    }

    if (res.success) {
      toast.success('Successfully resend verification code');
    }
  };

  const handleSubmitNewPassword = async (value: string) => {
    setIsLoadingSubmitPassword(true);
    const res = await resetPasswordRequest(challengeId, value);

    if (res?.success) {
      setIsLoadingSubmitPassword(false);
      setCurrentStep(CurrentResetPasswordStep.STEP_6);
      return {success: true};
    }

    if (res?.error) {
      setIsLoadingSubmitPassword(false);
      return {error: res.error};
    }
    setIsLoadingSubmitPassword(false);
    return {success: true};
  };

  const handleGoBackToLogin = () => {
    window.routerHistory.push('/login');
  };

  const isOnMobile = () => {
    const userAgent = navigator.userAgent;

    const isMobile = /iPad|iPhone|iPod|Android/.test(userAgent);

    return isMobile;
  };

  const renderStep = (currentStep: CurrentResetPasswordStep) => {
    switch (currentStep) {
      case CurrentResetPasswordStep.STEP_1:
        return (
          <ResetPwdContainer>
            <HorizontalLogoWrapper>
              <img src="/assets/horizontal-logo-red.svg" alt="hypercare logo with text" />
            </HorizontalLogoWrapper>
            <HCHeadingTwo color={muiTheme.colors.black}>{RESET_PASSWORD_TITLE}</HCHeadingTwo>
            {isLoadingMaskedAddresses && <CircularProgress />}
            {maskedAddresses.length >= 1 && (
              <ResetPasswordMaskedEmailForm
                value={value}
                maskedAddresses={maskedAddresses}
                submitForm={handleInitiateResetPasswordFlow}
                handleValueChange={handleChange}
                isLoadingSendEmail={isLoadingSendEmail}
              />
            )}
            <HCTextContextThree>
              <Link style={{textDecoration: 'none', color: '#00859A'}} to={`/login`}>
                {BACK_TO_LOGIN_LINK}
              </Link>
            </HCTextContextThree>
          </ResetPwdContainer>
        );

      case CurrentResetPasswordStep.STEP_2:
        return (
          <CustomStatusMessage
            icon={<CircleFilledWithGreenCheck width={64} height={64} />}
            title={RESET_PASSWORD_SUCCESS_TITLE}
            description={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '10px',
                  textAlign: 'center',
                }}
              >
                <div>
                  <HCLabelOne color={muiTheme.colors.text}>
                    {RESET_PASSWORD_SUCCESS_DESCRIPTION(selectedMaskedAddress || '')}
                  </HCLabelOne>
                  <HCLabelOne color={muiTheme.colors.text}> {RESET_PASSWORD_SUCCESS_DESCRIPTION_2}</HCLabelOne>
                </div>
                <div>
                  <HCCustomSpan color={muiTheme.colors.text}>Didn't receive it? Check spam or</HCCustomSpan>{' '}
                  <HCCustomSpan
                    style={{cursor: 'pointer'}}
                    onClick={() => handleResend()}
                    color={muiTheme.colors.chatTeal}
                  >
                    resend email
                  </HCCustomSpan>
                </div>{' '}
              </div>
            }
            buttonText={BACK_TO_LOGIN_LINK}
            onBackButtonClick={handleGoBackToLogin}
          />
        );

      case CurrentResetPasswordStep.STEP_3:
        return (
          <ResetPwdContainer>
            <HorizontalLogoWrapper>
              <img src="/assets/horizontal-logo-red.svg" alt="hypercare logo with text" />
            </HorizontalLogoWrapper>
            <ResetPasswordEnterPasswordForm
              submitForm={handleSubmitNewPassword}
              isLoadingSubmitPassword={isLoadingSubmitPassword}
            />
          </ResetPwdContainer>
        );

      case CurrentResetPasswordStep.STEP_4:
        return (
          <ResetPwdContainer>
            <CustomStatusMessage
              icon={<CircleWithCrossInMiddle />}
              title={RESET_PASSWORD_LINK_EXPIRED_TITLE}
              description={RESET_PASSWORD_LINK_EXPIRED_DESCRIPTION}
              buttonText={BACK_TO_LOGIN_LINK}
              onBackButtonClick={() => handleGoBackToLogin()}
            />
          </ResetPwdContainer>
        );

      case CurrentResetPasswordStep.STEP_5:
        return (
          <CustomStatusMessage
            icon={<CircleWithCrossInMiddle />}
            title={RESET_PASSWORD_INVALID_LINK_TITLE}
            description={RESET_PASSWORD_INVALID_LINK_DESCRIPTION}
            buttonText={BACK_TO_LOGIN_LINK}
            onBackButtonClick={() => handleGoBackToLogin()}
          />
        );

      case CurrentResetPasswordStep.STEP_6:
        return (
          <CustomStatusMessage
            icon={<CircleFilledWithGreenCheck width={64} height={64} />}
            title={RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_TITLE}
            description={
              isOnMobile()
                ? RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_MOBILE_DESCRIPTION
                : RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_DESCRIPTION
            }
            buttonText={BACK_TO_LOGIN_LINK}
            onBackButtonClick={() => handleGoBackToLogin()}
            hideButton={isOnMobile()}
          />
        );
    }
  };

  if (loading) {
    return (
      <PageWrapper>
        <ResetPwdContainer>
          <CircularProgress />
        </ResetPwdContainer>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      {!loading && (
        <>
          <Helmet>
            <title>Reset Password - Hypercare</title>
          </Helmet>
          {renderStep(currentStep)}
        </>
      )}
    </PageWrapper>
  );
};
