// local
export const AUTH_INFO = 'authInfo';
export const HYPERCARE_USERNAME = 'hypercareUsername';
export const HYPERCARE_REGION = 'hypercareRegion';
export const SSO_VENDOR = 'ssoVendor';
export const ORG_URL = 'orgUrl';
export const ACCOUNT_DISCOVERY_FLOW_STEP = 'accountDiscoveryFlowStep';
export const CURRENT_ORGANIZATION = 'currentOrganization';
export const INTRO_DONE = 'intro-done';
export const ORGANIZATION_ACCOUNTS_DATA = 'organizationAccountsData';
export const EMAIL = 'email';
export const CURRENT_SELECTED_ORGANIZATION = 'currentSelectedOrganization';
export const ENCODED_DATA_FROM_MOBILE_CLIENTS = 'encodedDataFromMobileClients';
export const MOBILE_CLIENT_ID = 'mobileClientId';
export const MOBILE_DEVICE_ID = 'mobileDeviceId';
export const FCM_SECRETS = 'fcmSecrets';

// session
export const IS_EXPIRED = 'isExpired';
export const PREVIOUS_ORGANIZATION = 'previousOrganization';
export const IS_JOIN_ORG_FROM_ONBOARDING = 'isJoinOrgFromOnboarding';
export const IS_FINISHING_CREATE_ORGANIZATION = 'isFinishingCreateOrganization';
export const DESIRED_ROUTE = 'desiredRoute';
export const SESSION_ID = 'sessionID';

// debug
export const DEBUG = 'debug';

//PARAMS
export const SELECTED_ACCOUNT = 'selectedAccount';
export const REDIRECT_URI = 'redirect_uri';
export const USE_LOCAL_STORAGE = 'useLocalStorage';
export const VIEW = 'view';
export const FLOW_ENTRY = 'flowEntry';
export const REMOVE_ACCOUNT = 'removeAccount';
