import React from 'react';
import styled from '@emotion/styled';
import SignupPage from '../signup/SignupPage';

interface ICreateNewOrgProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  currentStep?: number;
  content?: React.ReactNode;
}

const CreateNewOrg: React.FC<ICreateNewOrgProps> = ({onBackButtonClick, content, currentStep = 1}) => {
  return (
    <SignupPage
      currentStep={currentStep}
      isDisabled={false}
      title="Create a new organization"
      description="What’s the name of your organization? Choose something your colleagues can easily recognize!"
      onBackButtonClick={onBackButtonClick}
      content={content}
    />
  );
};

export default CreateNewOrg;
