import React from 'react';
import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {OK} from '../../../../constants/strings';

export interface IErrorModalProps {
  title: string;
  description: string;
  isOpen: true;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttons?: Array<{
    type: 'primary' | 'secondary';
    buttonLabel: string;
    onClickHandler: () => void;
    id: string;
  }>;
}

export const ErrorModal = ({title, description, isOpen, setIsOpen, buttons}: IErrorModalProps) => {
  return (
    <AlertModal
      width="sm"
      title={title}
      titleFontSize="21px"
      subtitle={description}
      closeAlertModal={() => setIsOpen(false)}
      isAlertModalVisible={isOpen}
      alertModalButtons={
        buttons
          ? buttons
          : [
              {
                type: 'primary',
                buttonLabel: OK,
                onClickHandler: () => setIsOpen(false),
                id: 'cancel-btn',
              },
            ]
      }
    />
  );
};
