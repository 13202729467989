import gql from 'graphql-tag';

export default gql`
  mutation RegisterPushToken(
    $deviceType: DeviceType!
    $token: String!
    $pushService: PushService!
    $environment: PushServiceEnvironment!
  ) {
    selfMutation {
      registerPushToken(deviceType: $deviceType, token: $token, pushService: $pushService, environment: $environment) {
        __typename
        ... on RegisterPushTokenResponse {
          secret
          pushRegistration {
            id
            deviceType
            pushService
            environment
            # token
            userAgent
          }
        }
        ... on Error {
          message
        }
      }
    }
  }
`;
