import {BackButton, StyledBox} from '../styled/signup.styled';
import {Box} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {HCHeadingTwo, HCLabelOne} from '../../components/HypercareComponents';
import {muiTheme} from '../../styles/theme';
import React from 'react';

interface ILoginHeaderProps {
  title: string;
  description?: string;
  onBackButtonClick: () => void;
  headerTitle: React.ReactNode;
  hideBackButton?: boolean;
}

export const LoginHeader = ({
  title,
  description,
  onBackButtonClick,
  headerTitle,
  hideBackButton,
}: ILoginHeaderProps) => {
  return (
    <StyledBox>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {!hideBackButton ? (
          <BackButton onClick={onBackButtonClick}>
            <ArrowBackIcon />
          </BackButton>
        ) : null}

        <Box width="100%" display="flex" justifyContent="center">
          <Box display="flex" alignItems="center" maxWidth="90%" overflow="hidden" flexWrap="wrap">
            {headerTitle}
          </Box>
        </Box>
      </Box>
      <HCHeadingTwo color={muiTheme.colors.black} margin={'16px 0 16px 0'}>
        {title}
      </HCHeadingTwo>
      <HCLabelOne margin={'0 0 16px 0'}>{description}</HCLabelOne>
    </StyledBox>
  );
};
