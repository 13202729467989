import React from 'react';
import {Formik, FormikProps} from 'formik';
import InputLabel from '@mui/material/InputLabel';
import ErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import JoinOrgWithCode from 'src/microfrontend/joinCreateOrg/JoinOrgWithCode';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewNextStepButton from '../components/NextStepButton';
import * as yup from 'yup';
import {inviteCodeValidationSchema} from 'src/utils/validations';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';

interface IJoinOrgViewProps {
  email: string;
  onBackButtonClick?: any;
  viewModel?: any;
}

export interface IJoinOrgValidationSchema {
  inviteCode: string;
}

const validationSchema = yup.object().shape<IJoinOrgValidationSchema>({
  inviteCode: inviteCodeValidationSchema,
});

const JoinOrgView = ({email, onBackButtonClick, viewModel}: IJoinOrgViewProps) => {
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [error, setError] = React.useState({title: '', subTitle: ''});
  console.log(email);
  const onNextClick = (values: {inviteCode: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        organizatin_code: values.inviteCode,
      },
    });
  };

  return (
    <div>
      <JoinOrgWithCode
        onBackButtonClick={onBackButtonClick}
        currentStep={5}
        content={
          <Formik
            initialValues={{
              inviteCode: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              const result = await viewModel.handleNextButtonPressed({inviteCode: values.inviteCode, email});
              if (result?.error?.title) {
                setError(result.error);
                setShowErrorModal(true);
              } else if (result?.error) {
                actions.setFieldError('inviteCode', result.error);
              }
            }}
          >
            {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors}) => (
              <form onSubmit={handleSubmit}>
                <StyledTextField
                  fullWidth={true}
                  margin="normal"
                  variant="outlined"
                  label="Invite Code"
                  value={values.inviteCode}
                  name="inviteCode"
                  placeholder="Enter Organization Code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.inviteCode && errors.inviteCode}
                  error={touched.inviteCode && Boolean(errors.inviteCode)}
                />
                <NewNextStepButton label="Next" onClick={() => onNextClick(values)} disabled={!isValid}>
                  Next
                </NewNextStepButton>
              </form>
            )}
          </Formik>
        }
      />
      {showErrorModal ? (
        <AlertModal
          id="close-modal"
          width="sm"
          title={error.title}
          titleFontSize="21px"
          subtitle={error.subTitle}
          isAlertModalVisible={showErrorModal}
          closeAlertModal={() => setShowErrorModal(false)}
          alertModalButtons={[
            {
              type: 'primary',
              buttonLabel: 'Ok',
              onClickHandler: () => setShowErrorModal(false),
              id: 'close-modal',
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default JoinOrgView;
