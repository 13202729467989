import React from 'react';
import {
  FetchPaginatedRequestInput,
  FetchPaginatedRequestInputSTA,
  SearchRequestInput,
  UserRepository,
} from 'src/data/repository/UserRepository';

export const UserViewModel = () => {
  const repo = UserRepository();

  const fetchPaginatedUsers = ({continuationId, direction, scopes}: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedUsers({continuationId, direction, scopes});
    return {
      ...result,
    };
  };

  const fetchOrganizationTotalUsersCount = () => {
    const result = repo.useFetchOrganizationUserCount();
    return {
      data: result?.data?.organizationalUnitQuery.organizationalUnit?.paginatedMembers.totalResultsCount || 0,
      error: result.error,
      loading: result.loading,
    };
  };

  const fetchPaginatedInvites = ({continuationId, direction}: FetchPaginatedRequestInput) => {
    const result = repo.useFetchPaginatedInvites({continuationId, direction});
    return {
      ...result,
    };
  };

  const fetchPaginatedInvitesSTA = ({pageInfo}: FetchPaginatedRequestInputSTA) => {
    const result = repo.useFetchPaginatedInvitesSTA({pageInfo});
    return {
      ...result,
    };
  };

  const getSearchUsers = async ({text, continuationId, limit, options, scopes}: SearchRequestInput) => {
    const result = await repo.searchUsers({text, continuationId, limit, options, scopes});
    if (result?.errors) {
      const error = result?.errors[0].message;
      return {error};
    }

    if (!result?.data) {
      return {error: 'missing data'};
    }

    return {
      ...result.data.searchQuery.searchUsersInScopes,
    };
  };

  const fetchDepartmentUsers = ({departmentId}: {departmentId: number}) => {
    const result = repo.useFetchDepartmentUsers({departmentId});

    return {
      ...result,
    };
  };

  return {
    fetchPaginatedUsers,
    fetchPaginatedInvites,
    fetchPaginatedInvitesSTA,
    getSearchUsers,
    fetchOrganizationTotalUsersCount,
    fetchDepartmentUsers,
  };
};
