import gql from 'graphql-tag';

export interface ChangePasswordRequestVariables {
  currentPassword: string;
  newPassword: string;
}

export interface ChangePasswordMutationResponse {
  data: {
    selfMutation: {changePassword: ChangePasswordResult};
  };
}

type ChangePasswordResult = {__typename: 'FullOrganizationMember'; id: string} | ErrorResult;

type ErrorResult =
  | {__typename: 'VulnerablePassword'; message: string}
  | {__typename: 'PasswordRequirementsNotMet'; message: string}
  | {__typename: 'InvalidPassword'; message: string};

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    selfMutation {
      changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
        ... on FullOrganizationMember {
          id
          __typename
        }
        ... on Error {
          __typename
          message
        }
      }
    }
  }
`;
