import gql from 'graphql-tag';

export const FETCH_ORGANIZATION_BY_INVITE_CODE = gql`
  query GetOrganizationByInviteCode($code: String!) {
    findOrganizationByInviteCode(code: $code) {
      ...FindOrganizationResultFragment
    }
  }
  fragment FindOrganizationResultFragment on FindOrganizationResult {
    __typename
    ... on Error {
      __typename
      message
    }
    ...OrganizationFragment
  }

  fragment OrganizationFragment on Organization {
    __typename
    id
    name
    imageURL
    url
    loginMethods
    isOpenDoor
  }
`;
