import React from 'react';
import {FormikProps} from 'formik';
import ApiHelper from 'src/api';
import {RequestVerificationPayload, UserAddress} from 'src/types';
import {
  UPDATE_ADDRESS_VISIBILITY_NETWORK_ERROR,
  UPDATE_ADDRESS_LABEL_NETWORK_ERROR,
  TOO_MANY_CHALLENGES,
  MAX_ADDRESS_VERIFY_ATTEMPS,
  CHALLENGE_TIME_EXPIRED,
  CHALLENGE_NOT_FOUND,
  INVALID_OTP,
  ADDRESS_INVALID,
  ADDRESS_INVALID_MSG,
} from 'src/constants/networkError';
import {emailValidationSchema, phoneNumberValidationSchema} from 'src/utils/validations';
import convertTypeToReadbleText from 'src/utils/locating/convertAddressTypeToText';
import {ModifyAddressAccessResult} from 'src/gql/mutation/UpdateAddressAccessMutation';
import {toast} from 'react-toastify';
import {ChangeAddressLabelMutationResponse} from 'src/gql/mutation/ChangeAddressLabelMutation';
import {SuccessToast} from 'src/components/CustomToasts';
import {ADD_ADDRESS_STEP_3_EMPTY_LABEL_TOAST_MESSAGE, PROFILE_NEW_CONTACT_METHOD_SUCCESS} from 'src/constants/strings';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {FetchResult} from 'apollo-link';
import {formatPhoneNumber} from 'src/utils/formatPhoneNumber';
import {UpdateAddressLabelMutationResponse} from 'src/gql/v2/mutation/UpdateAddressLabelMutation';
import {UpdateAddressAccessResponse} from 'src/gql/v2/mutation/UpdateAddressAccessMutation';

interface CustomProps {
  activeStep: number;
  onActiveStepChanged: (step: number) => void;
  setLoading: (isLoading: boolean) => void;
  isVerified: boolean;
  setVerified: (isVerified: boolean) => void;
  validationCodeArray: string[];
  setValidationErrorMsg: (msg: string) => void;
  handleSubmit: () => void;
  addresses: UserAddress[];
  onCurrentAddressesChanged: (newCurrentAddresses: UserAddress[]) => void;
  newAddedAddress?: UserAddress;
  onNewAddedAddressChange: (address: UserAddress) => void;
  updateAddressLabel: ({
    address,
    type,
    updatedLabel,
  }) => Promise<{
    error: boolean | null;
    result: FetchResult<ChangeAddressLabelMutationResponse | UpdateAddressLabelMutationResponse> | null;
  }>;
  updateAddressVisibility: (
    address: string,
    type: string,
    access: string,
  ) => Promise<{
    error: boolean | null;
    result: FetchResult<ModifyAddressAccessResult | UpdateAddressAccessResponse> | null;
  }>;
  profileLabelAddressFeatureFlag: boolean;
  challengeId: string;
  setChallengeId: (challengeId: string) => void;
}

const ProfileAddAddressStepFormController = (customProps: CustomProps, formikProps: FormikProps<any>) => {
  const {values, setFieldError, setFieldTouched} = formikProps;

  const {
    activeStep,
    onActiveStepChanged,
    setLoading,
    setValidationErrorMsg,
    isVerified,
    setVerified,
    validationCodeArray,
    handleSubmit,
    addresses,
    onCurrentAddressesChanged,
    newAddedAddress,
    onNewAddedAddressChange,
    updateAddressLabel,
    updateAddressVisibility,
    profileLabelAddressFeatureFlag,
    challengeId,
    setChallengeId,
  } = customProps;

  const addAddressWithLabel = async () => {
    const {address, type, access, label} = values;

    if (!label) {
      toast.error(ADD_ADDRESS_STEP_3_EMPTY_LABEL_TOAST_MESSAGE);
      return;
    }

    const updateAddressLabelRequestPromise = updateAddressLabel({address, type, updatedLabel: label});
    const updateAddressVisibilityPromise = updateAddressVisibility(address, type, access);

    const [updateLabelResult, updateAddressVisibilityResult] = await Promise.all([
      updateAddressLabelRequestPromise,
      updateAddressVisibilityPromise,
    ]);

    if (updateLabelResult.error) {
      toast.error(UPDATE_ADDRESS_LABEL_NETWORK_ERROR);
    }

    if (updateAddressVisibilityResult.error) {
      toast.error(UPDATE_ADDRESS_VISIBILITY_NETWORK_ERROR);
    }

    if (updateLabelResult.result?.data && updateAddressVisibilityResult.result?.data) {
      if (!newAddedAddress) {
        console.error('missing new added address');
        return;
      }
      const newAddress: UserAddress = {
        ...newAddedAddress,
        ...values,
      };
      newAddress.address = newAddedAddress.address;
      const newAddressesArray = addresses.concat(newAddress);
      onCurrentAddressesChanged(newAddressesArray);

      toast.success(<SuccessToast title={PROFILE_NEW_CONTACT_METHOD_SUCCESS} />, {
        className: 'toast-message',
        autoClose: 5000,
      });
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.newAddressAddded,
        params: {
          type: values.type,
          address: values.address,
        },
      });

      handleSubmit();
    }
  };
  const addAddressWithOutLabel = async () => {
    const {address, type, access, label} = values;

    const updateAddressVisibilityPromise = updateAddressVisibility(address, type, access);

    const [updateAddressVisibilityResult] = await Promise.all([updateAddressVisibilityPromise]);

    if (updateAddressVisibilityResult.error) {
      toast.error(UPDATE_ADDRESS_VISIBILITY_NETWORK_ERROR);
    }

    if (updateAddressVisibilityResult.result?.data) {
      const newAddress: UserAddress = {
        ...newAddedAddress,
        ...values,
      };
      const newAddressesArray = addresses.concat(newAddress);
      onCurrentAddressesChanged(newAddressesArray);

      toast.success(<SuccessToast title={PROFILE_NEW_CONTACT_METHOD_SUCCESS} />, {
        className: 'toast-message',
        autoClose: 5000,
      });
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.newAddressAddded,
        params: {
          type: values.type,
          address: values.address,
        },
      });

      handleSubmit();
    }
  };

  const formattedAddressPayload =
    values.type === 'phoneNumber' ? `+1${formatPhoneNumber(values.address)}` : values.address;

  switch (activeStep) {
    case 0:
      const validationSchema = values.type === 'email' ? emailValidationSchema : phoneNumberValidationSchema;

      // Note: client user cant add pager address yet.
      validationSchema
        .validate(values.address)
        .then(async () => {
          setLoading(true);
          const requestVerificationPayload: RequestVerificationPayload = {
            address: formattedAddressPayload,
            type: values.type,
          };

          const requestVerificationResult = isVerified
            ? null
            : await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA(requestVerificationPayload);
          if ((requestVerificationResult && requestVerificationResult?.data?.response) || isVerified) {
            if (requestVerificationResult) {
              setChallengeId(requestVerificationResult?.data?.response?.challengeId);
            }
            onActiveStepChanged(activeStep + 1);
            setLoading(false);
          } else {
            if (
              requestVerificationResult &&
              requestVerificationResult?.data?.errors &&
              requestVerificationResult?.data?.errors[0]
            ) {
              let errorCode = requestVerificationResult?.data?.errors[0].name;
              if (errorCode === TOO_MANY_CHALLENGES) {
                throw {errors: [requestVerificationResult?.data?.errors[0].message]};
              }
              if (errorCode === ADDRESS_INVALID) {
                throw {errors: [requestVerificationResult?.data?.errors[0].message]};
              }
              throw {
                errors: ['Unknown error occurred, please check your internet connection and try again'],
              };
            } else {
              throw {
                errors: ['Failed to request validation code, please check your internet connection and try again'],
              };
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          // Note: set false as useFormik validation default shouldValidate again and will reset setFieldError
          setFieldTouched('address', true, false);
          setFieldError('address', err.errors[0]);
        });
      break;

    case 1:
      (async () => {
        try {
          setValidationErrorMsg('');
          setLoading(true);
          const verificationCode = validationCodeArray.reduce((acc, current) => acc + current);
          const result = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, verificationCode);
          if (result && result.data?.response) {
            const addAddressResult = await ApiHelper.PrivateEndpoints.addUserAddress(challengeId);
            if (addAddressResult && addAddressResult?.data.data.selfMutation.addAddress.address) {
              onNewAddedAddressChange(addAddressResult.data.data.selfMutation.addAddress);
              setLoading(false);
              setVerified(true);
              handleSubmit();
            } else {
              throw {
                errors: [addAddressResult && addAddressResult?.data.data.selfMutation.addAddress.message],
              };
            }
          } else {
            const errorObj = result && result?.data?.errors?.[0].name;
            switch (errorObj) {
              case MAX_ADDRESS_VERIFY_ATTEMPS:
                throw {
                  errors: [
                    `You’ve failed to verify your ${convertTypeToReadbleText(
                      values.type,
                    )} too many times. Enter a different ${convertTypeToReadbleText(values.type)} or try again later.`,
                  ],
                };
              case CHALLENGE_TIME_EXPIRED:
                throw {
                  errors: [result && result?.data?.errors?.[0].message],
                };
              case CHALLENGE_NOT_FOUND:
                throw {
                  errors: [result && result?.data?.errors?.[0].message],
                };
              case INVALID_OTP:
                throw {
                  errors: [result && result?.data?.errors?.[0].message],
                };
              default:
                throw {
                  errors: ['Unknown error occurred, failed to verify code'],
                };
            }
          }
        } catch (err: any) {
          setLoading(false);
          setValidationErrorMsg(err.errors[0]);
        }
      })();
      break;

    case 2:
      profileLabelAddressFeatureFlag ? addAddressWithLabel() : addAddressWithOutLabel();
      break;
    default:
      break;
  }
};

export default ProfileAddAddressStepFormController;
