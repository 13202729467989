import React from 'react';
import {connect} from 'react-redux';
import client from 'src/apollo';
import {AuthContext} from 'src/auth/AuthProvider';
import GetSelfOrganizations from 'src/gql/query/GetUserOrganizations';
import FetchOrganizationTOSStatus from 'src/gql/query/FetchOrganizationTOSStatus';
import JoinOrgModal from 'src/pages/JoinOrganizationPage/join-organization/JoinOrganizationModal';
import AcceptTOSModal from 'src/components/AcceptTOSModal';
import store from 'src/redux';
import {GetUserWithPhoneAndEmail} from 'src/gql/query/GetUserByIds';
import {useLocation} from 'react-router';
import InviteColleaguesModal from 'src/components/InviteColleaguesModal';
import StatusSuggestionModal from 'src/pages/MessengerPage/messenger/change-status/StatusSuggestionModal';
import {OrganizationScope, User} from 'src/types';
import GetSelfProfile from 'src/gql/query/GetSelfProfile';
import {IS_FINISHING_CREATE_ORGANIZATION, IS_JOIN_ORG_FROM_ONBOARDING} from 'src/constants/storageKeys';
import {BUSY, UNAVAILABLE} from 'src/constants/workStatus';
import {UpdateStatusOpenAppEventDialog} from 'src/components/StatusSettingsDialog';

// TODO: if there exist pending invites, show that modal instead of create organization modal

const PromptsManager = ({isLoggedIn, currentOrganization}) => {
  const [showJoinOrgModal, setJoinOrgModal] = React.useState(false);
  const [showTOSModal, setTOSModal] = React.useState(false);
  const [showInviteModal, setInviteModal] = React.useState(false);
  const [tosURL, setTOSUrl] = React.useState('');
  const [showStatusSuggestionModal, setShowStatusSuggestionModal] = React.useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = React.useState(false);

  const routerLocation = useLocation();
  const isInJoinOrgPage = routerLocation.pathname.includes('joinorganization');
  const isSignoutPage = routerLocation.pathname.includes('sso/signout');

  /**
   * Show user Status Suggestion Modal when user entering the page with a unavailable status
   */
  React.useEffect(() => {
    const determineCurrentStatus = async () => {
      const {data} = await client.query({
        query: GetSelfProfile,
      });
      const {workStatus} = data.me as User;
      if (workStatus === UNAVAILABLE) setShowStatusSuggestionModal(true);
      if (workStatus === BUSY) setShowUpdateStatusDialog(true);
    };

    if (isLoggedIn && !isSignoutPage) determineCurrentStatus();
  }, [isLoggedIn]);

  /**
   * Show Join Organization when there does not exist any org
   */
  React.useEffect(() => {
    const determineHasOrganization = async () => {
      const {data} = await client.query({
        query: GetSelfOrganizations,
      });
      const organizationList: OrganizationScope[] = data.me.organizations;

      if (organizationList && organizationList.length === 0) {
        const result = await client.query({
          query: GetUserWithPhoneAndEmail,
          variables: {ids: [data.me.id]},
        });
        const {addresses} = result.data.users[0];
        const isEmailFind = addresses && Boolean(addresses.find((address) => address.type === 'email'));

        if (isEmailFind) {
          // isJoinOrgFromOnboarding is set to session store when create and switch that organization
          if (!isInJoinOrgPage && !sessionStorage.getItem(IS_JOIN_ORG_FROM_ONBOARDING)) {
            setJoinOrgModal(true);
          }
        }
      } else {
        sessionStorage.removeItem(IS_JOIN_ORG_FROM_ONBOARDING);
      }
    };

    if (!isLoggedIn || isSignoutPage) return;

    if (isInJoinOrgPage) {
      setJoinOrgModal(false);
    } else {
      determineHasOrganization();
    }
  }, [isLoggedIn, routerLocation, isInJoinOrgPage]);

  /**
   * Show Terms of service if user first login or switch org when:
   * - user tos status is false
   * - organization has a tosUrl
   */
  React.useEffect(() => {
    const determineOrganizationTOSStatus = async () => {
      const {organizationId} = store.getState().organization;
      if (!organizationId) return;

      const {data} = await client.query({
        query: FetchOrganizationTOSStatus,
        variables: {organizationId},
      });
      // flag for check is user accepted org tos
      const {organizationTosStatus} = data.me;

      if (!organizationTosStatus) {
        const selfOrganizations = await client.query({
          query: GetSelfOrganizations,
        });
        const organizationsList: OrganizationScope[] = selfOrganizations.data.me.organizations;
        const selectedOrg = organizationsList.find((organization) => organization.id === organizationId);
        if (!selectedOrg) return;

        const {termsOfServiceUrl} = selectedOrg;
        setTOSModal(true);
        setTOSUrl(termsOfServiceUrl);
      }
    };

    if (isLoggedIn && !isSignoutPage) {
      determineOrganizationTOSStatus();
    } else {
      if (showTOSModal) setTOSModal(false);
      if (tosURL) setTOSUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, isLoggedIn]);

  /**
   * Show Create organization popup when user just finshed creating organization
   * - the flag will be set in session storage and clear on modal mount
   */
  React.useEffect(() => {
    if (!isLoggedIn || isSignoutPage) return;
    if (sessionStorage.getItem(IS_FINISHING_CREATE_ORGANIZATION) && currentOrganization.organizationId) {
      setInviteModal(true);
    }
  }, [isLoggedIn, currentOrganization]);

  if (isLoggedIn && showJoinOrgModal) {
    return <JoinOrgModal isOpen={showJoinOrgModal} closeModal={() => setJoinOrgModal(false)} />;
  }

  if (isLoggedIn && showTOSModal && tosURL) {
    return (
      <AcceptTOSModal
        tosURL={tosURL}
        isOpen={showTOSModal}
        currentOrganization={currentOrganization}
        closeModal={() => {
          setTOSUrl('');
          setTOSModal(false);
        }}
      />
    );
  }

  if (isLoggedIn && showInviteModal) {
    return <InviteColleaguesModal isOpen={showInviteModal} closeModal={() => setInviteModal(false)} />;
  }

  if (isLoggedIn && showStatusSuggestionModal) {
    return (
      <StatusSuggestionModal
        isOpen={showStatusSuggestionModal}
        closeModal={() => setShowStatusSuggestionModal(false)}
      />
    );
  }

  if (isLoggedIn && showUpdateStatusDialog) {
    return (
      <UpdateStatusOpenAppEventDialog
        openUpdateStatusDialog={showUpdateStatusDialog}
        closeUpdateStatusDialog={() => setShowUpdateStatusDialog(false)}
      />
    );
  }

  return null;
};

const mapStateToProps = (state: any) => ({
  currentOrganization: state.organization,
});

const RenderPropsManager = React.memo(connect(mapStateToProps)(PromptsManager));

export default () => (
  <AuthContext.Consumer>{({isLoggedIn}) => <RenderPropsManager isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
