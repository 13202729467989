import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import {localStorageService} from '../../services/localStorageService';
import {OrganizationAccountsCacheData} from '../../types/sta';
import {INTRO_DONE, ORGANIZATION_ACCOUNTS_DATA, REMOVE_ACCOUNT} from '../../constants/storageKeys';
import {LogoutViewModel} from '../LoginPage/sta/view-models/LogoutViewModel';
import {toast} from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import {AuthPayloadDTO} from '../../types';
import {AuthContext} from '../../auth';
import {getAdminWebAppUrl} from '../../utils/sta/staUtils';

interface SignOutProps {
  logout: () => void;
}

const SignOut = ({logout}: SignOutProps) => {
  let urlSearchParams = new URLSearchParams(window.location.search);
  let shouldRemoveAccount = urlSearchParams.get(REMOVE_ACCOUNT);
  const {userId} = useParams<{userId: string}>();
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const {logoutOfAccount, removeAccount} = LogoutViewModel(logout);

  const updateCacheData = (savedOrganizations: AuthPayloadDTO[]) => {
    const updatedCache: OrganizationAccountsCacheData = {
      savedOrganizations,
      selectedAccountUserId: '',
    };
    localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, updatedCache);
  };

  const handleLogout = () => {
    if (!userId || !currentCacheData) {
      window.routerHistory.push('/');
      return;
    }

    const accountToLogout = currentCacheData?.savedOrganizations.find((acc) => acc.user.id === userId);

    if (!accountToLogout) {
      window.routerHistory.push('/');
      return;
    }

    if (shouldRemoveAccount) {
      const removeResponse = removeAccount(accountToLogout);

      if (!removeResponse?.success) {
        toast.error('Failed to log out and remove account. Please try again.');
        return;
      }

      if (removeResponse?.success) {
        toast.info(
          `${accountToLogout?.user.firstname} ${accountToLogout?.user.lastname} (${accountToLogout?.organization?.name}) has been removed from browser.`,
        );

        if (removeResponse.data?.savedOrganizations.length === 0) {
          window.location.href = getAdminWebAppUrl();
        } else {
          updateCacheData(removeResponse.data?.savedOrganizations || []);
          window.localStorage.setItem(INTRO_DONE, '1');
          window.routerHistory.push('/login');
        }
      }
    } else {
      const logoutResponse = logoutOfAccount(accountToLogout);

      if (!logoutResponse?.success) {
        toast.error('Failed to log out. Please try again.');
        return;
      }

      if (logoutResponse?.success) {
        toast.info(
          `Logged out of account ${accountToLogout?.user.firstname} ${accountToLogout?.user.lastname} (${accountToLogout?.organization?.name})`,
        );
        updateCacheData(logoutResponse.data?.savedOrganizations || []);
        window.localStorage.setItem(INTRO_DONE, '1');
        window.routerHistory.push('/login');
      }
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <CircularProgress />;
};

export const SignOutHandler = () => {
  return <AuthContext.Consumer>{({logout}) => <SignOut logout={logout} />}</AuthContext.Consumer>;
};
