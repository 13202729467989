import React, {useCallback, useState} from 'react';
import {getAllLoggedInAccounts} from '../utils/sta/staUtils';
import {UserRepository} from '../data/repository/UserRepository';

export const useUnreadChatCount = () => {
  const [unreadCount, setUnreadCount] = useState<Record<string, number>>({});
  const {fetchUnreadChatCountForUser} = UserRepository();
  const loggedInAccounts = getAllLoggedInAccounts();

  const fetchUnreadCount = useCallback(async () => {
    if (loggedInAccounts.length === 0) return;

    try {
      const promises = loggedInAccounts.map((acc) => fetchUnreadChatCountForUser(acc.accessToken || ''));
      const responses = await Promise.all(promises);

      const unreadCountData = responses.reduce((acc, response) => {
        if ('error' in response) return acc;

        const {id} = response.data.me;
        const {count} = response.data.me.unreadChatCount[0];
        acc[id] = count;
        return acc;
      }, {} as Record<string, number>);

      setUnreadCount(unreadCountData);
    } catch (error) {
      console.error('Error fetching unread chat counts', error);
    }
  }, [fetchUnreadChatCountForUser]);

  return {unreadCount, fetchUnreadCount};
};
