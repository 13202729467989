import {AuthPayload} from 'src/types';
import HypercareSignupRepository from '../HypercareSignupRepository';
import sleep from 'src/utils/sleep';
import {ALREADY_EXISTS, INVITE_CODE_NOT_FOUND, VULNERABLEPASSWORD} from 'src/constants/networkError';
import {AuthHelper, AuthProvider} from 'src/auth';
import ApiHelper from 'src/api';
import {ActivateShellAccountMutationInputPayload} from 'src/gql/v2/mutation/ActivateShellAccountMutation';

class HypercareSignupJobTitleOTPLoginViewModel {
  nextButtonPressed;
  repository: HypercareSignupRepository;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    try {
      const fetchOrgByUrl = await ApiHelper.PublicEndpoints.fetchOrganizationByURL(values.organizationUrl);
      if (fetchOrgByUrl.data?.findOrganizationByURL.__typename === 'OrganizationNotFound') {
        this.nextButtonPressed(values);
      }
      const doesContainPassword = fetchOrgByUrl?.data?.findOrganizationByURL.loginMethods.includes('password');
      if (fetchOrgByUrl?.data?.findOrganizationByURL.id && doesContainPassword) {
        console.log('hereeeee');
        const newValues = {...values, onlyOTP: false};
        this.nextButtonPressed(newValues);
      } else if (values.activateShellAccountFlow === 'true') {
        console.log('values', values);
        const activateResult = await this.handleActivateShellAccount(
          values.challengeId,
          values.organizationUrl,
          values.userDetails,
        );
        if (activateResult?.success) {
          const partialAccessTokenResponse = await AuthHelper.exchangeOTPTokenForPartialAccessToken(values.challengeId);
          if (partialAccessTokenResponse?.data) {
            const authResult = await AuthHelper.exchangeToken(
              values.organizationUrl,
              partialAccessTokenResponse.data.access_token,
            );

            const result = await this.handleAcceptEula(authResult.data?.access_token as string);

            // TODO: fix with proper handling - temp solution as backend input delay
            // e.g. might not write the status before login call
            await sleep(2000);

            if (result) {
              const newValues = {...values, onlyOTP: true};
              this.nextButtonPressed(newValues);
            }
          } else {
            return {error: 'accessToken'};
          }
        } else {
          return {error: 'activateResult.data'};
        }
      } else if (values.inviteCode) {
        console.log('values', values);
        if (values.organizationUrl) {
          // const newValues = {...values, organizationUrl: organizationUrl, orgId: orgId, loginMethods: loginMethods};
          const createUserAccountResult = await this.handleCreateUserAccount(values);
          if (createUserAccountResult?.data.data.createAccount.firstName) {
            const partialAccessTokenResponse = await AuthHelper.exchangeOTPTokenForPartialAccessToken(
              values.challengeId,
            );
            console.log(values.organizationUrl, 'values.organizationUrl');
            if (partialAccessTokenResponse?.data) {
              const authResult = await AuthHelper.exchangeToken(
                values.organizationUrl,
                partialAccessTokenResponse.data.access_token,
              );
              console.log(authResult);
              const result = await this.handleAcceptEula(authResult.data?.access_token as string);

              // TODO: fix with proper handling - temp solution as backend input delay
              // e.g. might not write the status before login call
              await sleep(2000);

              if (result) {
                const newValues = {...values, onlyOTP: true};
                this.nextButtonPressed(newValues);
              }
            } else {
              return {error: 'accessToken'};
            }
          } else {
            return {error: createUserAccountResult.data.data.createAccount.message};
          }
        }
      } else if (values.orgName && values.organizationUrl) {
        const {inviteCode, ...rest} = values;
        const newValues = inviteCode ? {...rest, inviteCode} : rest;
        const createUserAccountResult = await this.handleCreateUserAccount(newValues);
        if (createUserAccountResult?.data.data.createAccount.firstName) {
          const partialAccessTokenResponse = await AuthHelper.exchangeOTPTokenForPartialAccessToken(values.challengeId);
          if (partialAccessTokenResponse?.data) {
            const authResult = await AuthHelper.exchangeToken(
              values.organizationUrl,
              partialAccessTokenResponse.data.access_token,
            );
            const result = await this.handleAcceptEula(authResult.data?.access_token as string);

            // TODO: fix with proper handling - temp solution as backend input delay
            // e.g. might not write the status before login call
            await sleep(2000);

            if (result) {
              const newValues = {...values, onlyOTP: true};
              this.nextButtonPressed(newValues);
            }
          } else {
            return {error: 'accessToken'};
          }
        } else {
          return {error: createUserAccountResult.data.data.createAccount.message};
        }
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };

  handleCreateUserAccount = async (payload) => {
    const createUserResult = await this.repository?.createSTAUserAccount(payload);
    return createUserResult;
  };

  handleAcceptEula = async (accessToken) => {
    const fetchEulaPayload = await this.repository?.acceptEula(accessToken);
    return fetchEulaPayload;
  };

  handleActivateShellAccount = async (challengeId, organizationUrl, userDetails) => {
    const activeShellAccountResult = await this.repository?.activateShellAccount({
      challengeId,
      organizationUrl,
      userDetails,
    });

    if ('data' in activeShellAccountResult && activeShellAccountResult.data.activateAccount.id) {
      return {success: true};
    } else {
      return {
        success: false,
        data: 'data' in activeShellAccountResult && activeShellAccountResult.data.activateAccount,
      };
    }
  };
}

export default HypercareSignupJobTitleOTPLoginViewModel;
